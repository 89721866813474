// @import './card';
// @import './title';
// @import './image';


[instructor] {
  @extend .col2;

  [picture] {
    @extend [profileImage];

    img {
      @extend [imageFit];
    }
  }

  [profile] {
    @extend .row3;
    grid-gap: 6px;

    [name] {
      @extend [smallTitle];
      @extend [semiBold];
    }

    [position] {
      @extend [microTitle];
    }

    [institute] {
      @extend [extraSmallTitle];
    }
  }
}