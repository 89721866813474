@import '../core/variables';

// career
[career] {
  display              : grid;
  grid-gap             : $smallGap;
  grid-template-columns: auto 1fr auto;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'career-logo career-detail career-button';

  [careerLogo] {
    grid-area: career-logo;
  }

  [careerDetail] {
    grid-area            : career-detail;
    display              : grid;
    grid-gap             : 0px;
    grid-template-columns: 1fr;
    grid-template-rows   : 1fr auto;
    grid-template-areas  :
      'career-detail-header'
      'career-detail-summary';

    [careerDetailHeader] {
      grid-area                    : career-detail-header;
      // display                   : grid;
      // grid-gap                  : 0px;
      // grid-template-columns     : 1fr;
      // grid-template-rows        : 1fr auto auto auto;
      // grid-template-areas       :
      //   'career-detail-header-title'
      //   'career-detail-header-subtitle'
      //   'career-detail-header-date'
      //   'career-detail-header-address';

      // [careerDetailHeaderTitle] {
      //   grid-area: 'career-detail-header-title'
      // }

      // [careerDetailHeaderSubtitle] {
      //   grid-area: 'career-detail-header-subtitle'
      // }

      // [careerDetailHeaderDate] {
      //   grid-area: 'career-detail-header-date'
      // }

      // [careerDetailHeaderAddress] {
      //   grid-area: 'career-detail-header-address'
      // }
    }

    [careerDetailSummary] {
      padding-top          : $smallGap;
      grid-area            : career-detail-summary;
      display              : grid;
      grid-gap             : 0px;
      grid-template-columns: 1fr;
      grid-template-rows   : auto 1fr;
    }
  }

  [careerButton] {
    grid-area            : career-button;
    display              : grid;
    grid-gap             : $microGap;
    grid-template-columns: auto 1fr;
    grid-template-rows   : 1fr;
    grid-template-areas  : 'career-delete-button career-edit-button';

    [careerDeleteButton] {
      grid-area: 'career-delete-button';
    }

    [careerEditButton] {
      grid-area: 'career-edit-button';
    }
  }
}

// responsive
@media(max-width: $nanoBreak) {}

@media(max-width: $microBreak) {}

@media(max-width: $extraSmallBreak) {
  [career] {
    grid-template-columns: auto 1fr;
    grid-template-rows   : auto 1fr;
    grid-template-areas  :
      'career-logo career-button'
      'career-detail career-detail';
  }
}

@media(max-width: $smallBreak) {}

@media(max-width: $mediumBreak) {}

@media(max-width: $largeBreak) {}

@media(max-width: $extraLargeBreak) {}