@use '@angular/material' as mat;
@import './typography/fusion-default.typography';
@import '../../theme/core/variables';

// primary
@import './palettes/fusion-primary.palette';

// accent
@import './palettes/fusion-accent.palette';

// warn
@import './palettes/fusion-warn.palette';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
// @include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

// dynamic
$fusion-primary-palette: (
  50: var(--material-palette-primary-50),
  100: var(--material-palette-primary-100),
  200: var(--material-palette-primary-200),
  300: var(--material-palette-primary-300),
  400: var(--material-palette-primary-400),
  500: var(--material-palette-primary-500),
  600: var(--material-palette-primary-600),
  700: var(--material-palette-primary-700),
  800: var(--material-palette-primary-800),
  900: var(--material-palette-primary-900),
  A100: var(--material-palette-primary-A100),
  A200: var(--material-palette-primary-A200),
  A400: var(--material-palette-primary-A400),
  A700: var(--material-palette-primary-A700),
  contrast: (
    50: var(--material-palette-primary-contrast-50),
    100: var(--material-palette-primary-contrast-100),
    200: var(--material-palette-primary-contrast-200),
    300: var(--material-palette-primary-contrast-300),
    400: var(--material-palette-primary-contrast-400),
    500: var(--material-palette-primary-contrast-500),
    600: var(--material-palette-primary-contrast-600),
    700: var(--material-palette-primary-contrast-700),
    800: var(--material-palette-primary-contrast-800),
    900: var(--material-palette-primary-contrast-900),
    A100: var(--material-palette-primary-contrast-A100),
    A200: var(--material-palette-primary-contrast-A200),
    A400: var(--material-palette-primary-contrast-A400),
    A700: var(--material-palette-primary-contrast-A700),
  ),
);

$fusion-secondary-palette: (
  50: var(--material-palette-secondary-50),
  100: var(--material-palette-secondary-100),
  200: var(--material-palette-secondary-200),
  300: var(--material-palette-secondary-300),
  400: var(--material-palette-secondary-400),
  500: var(--material-palette-secondary-500),
  600: var(--material-palette-secondary-600),
  700: var(--material-palette-secondary-700),
  800: var(--material-palette-secondary-800),
  900: var(--material-palette-secondary-900),
  A100: var(--material-palette-secondary-A100),
  A200: var(--material-palette-secondary-A200),
  A400: var(--material-palette-secondary-A400),
  A700: var(--material-palette-secondary-A700),
  contrast: (
    50: var(--material-palette-secondary-contrast-50),
    100: var(--material-palette-secondary-contrast-100),
    200: var(--material-palette-secondary-contrast-200),
    300: var(--material-palette-secondary-contrast-300),
    400: var(--material-palette-secondary-contrast-400),
    500: var(--material-palette-secondary-contrast-500),
    600: var(--material-palette-secondary-contrast-600),
    700: var(--material-palette-secondary-contrast-700),
    800: var(--material-palette-secondary-contrast-800),
    900: var(--material-palette-secondary-contrast-900),
    A100: var(--material-palette-secondary-contrast-A100),
    A200: var(--material-palette-secondary-contrast-A200),
    A400: var(--material-palette-secondary-contrast-A400),
    A700: var(--material-palette-secondary-contrast-A700),
  ),
);

$fusion-warn-palette: (
  50: var(--material-palette-warn-50),
  100: var(--material-palette-warn-100),
  200: var(--material-palette-warn-200),
  300: var(--material-palette-warn-300),
  400: var(--material-palette-warn-400),
  500: var(--material-palette-warn-500),
  600: var(--material-palette-warn-600),
  700: var(--material-palette-warn-700),
  800: var(--material-palette-warn-800),
  900: var(--material-palette-warn-900),
  A100: var(--material-palette-warn-A100),
  A200: var(--material-palette-warn-A200),
  A400: var(--material-palette-warn-A400),
  A700: var(--material-palette-warn-A700),
  contrast: (
    50: var(--material-palette-warn-contrast-50),
    100: var(--material-palette-warn-contrast-100),
    200: var(--material-palette-warn-contrast-200),
    300: var(--material-palette-warn-contrast-300),
    400: var(--material-palette-warn-contrast-400),
    500: var(--material-palette-warn-contrast-500),
    600: var(--material-palette-warn-contrast-600),
    700: var(--material-palette-warn-contrast-700),
    800: var(--material-palette-warn-contrast-800),
    900: var(--material-palette-warn-contrast-900),
    A100: var(--material-palette-warn-contrast-A100),
    A200: var(--material-palette-warn-contrast-A200),
    A400: var(--material-palette-warn-contrast-A400),
    A700: var(--material-palette-warn-contrast-A700),
  ),
);

// Define the default theme (same as the example above).
$fusion-default-primary: mat.define-palette($fusion-primary, 500);
$fusion-default-accent: mat.define-palette($fusion-accent, 500);
$fusion-default-warn: mat.define-palette($fusion-warn, 500);

$fusion-default-theme: mat.define-light-theme((
  color: (
   primary: $fusion-default-primary,
   accent: $fusion-default-accent,
   warn: $fusion-default-warn
 ),
 density: 0,
));
@include mat.core-theme($fusion-default-theme);
// IN CONSUMER APP

// customize fusion default typography
$custom-typography: fusion-typography-config(
  $font-family: var(--font-family),
);

// apply Typography
@include mat.all-component-typographies($custom-typography);

// Include the default theme styles.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($fusion-default-theme);
// @include mat.all-component-themes($fusion-default-theme);

// custom mat dialog style
.mat-mdc-dialog-container {
  padding: 0px !important;
  margin: auto 9px 5px 9px !important;
  // height : 80vh !important;
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  z-index: 1000;
}

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label {
  white-space: normal !important;
}

.mat-h5,
.mat-typography h5 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  font-family: var(--font-family-header);
}

.mat-h6,
.mat-typography h6 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  font-family: var(--font-family-header);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  width: 130px;
}

.mat-mdc-menu-panel {
  min-height: unset !important;
}

// @media(max-width: $extraSmallBreak) {
//   .mat-vertical-content {
//     padding: unset !important;
//   }
// }
