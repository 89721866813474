@import '../core/variables';

[section] {
  display              : grid;
  grid-gap             : $largeGap;
  grid-template-columns: 1fr 2fr;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'section-header section-content';

  [sectionHeader] {
    grid-area            : section-header;
    display              : grid;
    grid-gap             : $microGap;
    grid-template-columns: 1fr;
    grid-template-rows   : auto 1fr;
    grid-template-areas  :
      'section-title'
      'section-subtitle';

    [sectionTitle] {
      grid-area: section-title;
    }

    [sectionSubitle] {
      grid-area: section-subtitle;
    }
  }

  [sectionContent] {
    grid-area: section-content;
  }
}

// responsive view
@media screen and (max-width: $smallBreak) {
  [section] {
    grid-template-columns: 1fr;
    grid-template-rows   : auto 1fr;
    grid-template-areas  : 'section-header' 'section-content';
  }
}