.colN {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 0.5fr));
}

.col1 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'col1__section-one col1__section-two';

  &__section-one {
    grid-area: col1__section-one;
  }

  &__section-two {
    grid-area: col1__section-two;
  }
}

.col2 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: auto 1fr;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'col2__section-one col2__section-two';

  &__section-one {
    grid-area: col2__section-one;
  }

  &__section-two {
    grid-area: col2__section-two;
  }
}

.col3 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'col3__section-one col3__section-two col3__section-three';

  &__section-one {
    grid-area: col3__section-one;
  }

  &__section-two {
    grid-area: col3__section-two;
  }

  &__section-three {
    grid-area: col3__section-three;
  }
}

.col4 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows   : 1fr;
  grid-template-areas  : 'col4__section-one col4__section-two col4__section-three col4__section-four';

  &__section-one {
    grid-area: col4__section-one;
  }

  &__section-two {
    grid-area: col4__section-two;
  }

  &__section-three {
    grid-area: col4__section-three;
  }

  &__section-four {
    grid-area: col4__section-four;
  }
}