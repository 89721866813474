
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
// .mat-tab-label {
//   font-family: var(--font-family);
// }

// .mat-mdc-tab-group {
//   font-family: var(--font-family);
// }

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary);
}

.mat-mdc-tab-header {
  border-bottom: 0.8px solid rgb(var(--primary-rgb), 0.2);
}
