// button {
//   cursor: pointer;

//   &[mat-flat-button] {
//     font-family        : var(--font-family);
//     font-size          : 16px;
//     font-weight        : 400;
//     border-radius      : 4px;
//     // background-color: var(--primary);
//     // color           : var(--white);
//     border             : 1px solid var(--primary);

//     &.mat-primary {
//       // background-color: var(--primary);
//     }

//     &:hover {
//       // background-color: var(--secondary);
//       // border-color    : var(--secondary);
//     }

//     &[disabled] {
//       // background-color: var(--gray-2);
//       border: 1px solid var(--gray-2);
//       cursor: not-allowed;
//     }
//   }

//   &[mat-stroked-button] {
//     text-transform     : none;
//     font-family        : var(--font-family);
//     font-size          : 16px;
//     font-weight        : 400;
//     border-radius      : 4px;
//     // background-color: transparent;
//     // color           : var(--primary);
//     border             : 1px solid var(--primary);

//     &:hover {
//       // background-color: var(--primary);
//       // color           : var(--white);
//     }

//     &[disabled] {
//       // background-color: var(--gray-2);
//       border: 1px solid var(--gray-2);
//       cursor: not-allowed;
//     }
//   }
// }

.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  font-size     : 16px;
  // font-family: var(--font-family);
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  padding: 0px 36px !important;
}

.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  min-width: 135px !important;
}

.custom-button.mat-mdc-button {
  padding: 0px !important;
}

.custom-round-button.mat-mdc-unelevated-button,
.custom-round-button.mat-mdc-raised-button,
.custom-round-button.mat-mdc-outlined-button {
  border-radius: 50px !important;
}

.custom-button.mat-mdc-fab {
  width : 31px !important;
  height: 31px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.custom-button.mat-mdc-fab .mat-button-wrapper {
  padding: 0px !important;

  & .material-icons {
    font-size: 17px !important;
  }
}

.custom-medium-button.mat-mdc-fab {
  width : 40px !important;
  height: 40px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.custom-medium-button.mat-mdc-fab .mat-button-wrapper {
  padding: 0px !important;

  & .material-icons {
    font-size: 22px !important;
  }
}