[overlay] {
  position: absolute;
  top     : 0;
  bottom  : 0;
  left    : 0;
  right   : 0;
  height  : 100%;
  width   : 100%;
  opacity : 1;

  &[darkShade] {
    transition      : 1.5s ease;
    background-color: rgba(20, 16, 52, 0.69);

    &:hover {
      background-color: rgba(20, 16, 52, 0.30);
    }
  }

  &[grayShade] {
    transition: 1.5s ease;
    background: -webkit-gradient(linear, right top, left top, color-stop(33%, rgb(244, 246, 248, .6)), color-stop(60%, #f4f6f8));
    background: linear-gradient(to left, rgb(244, 246, 248, .6) 33%, #f4f6f8 60%);
  }
}

[overlayShade] {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  left            : 0;
  right           : 0;
  height          : 100%;
  width           : 100%;
  opacity         : 1;
  transition      : 1.5s ease;
  background-color: rgba(20, 16, 52, 0.69);
}

[overlayShade]:hover {
  background-color: rgba(20, 16, 52, 0.30);
}

[textShadow] {
  text-shadow: 0px 1px 2px var(--gray-10)
}

[overlayGrayShade] {
  position  : absolute;
  top       : 0;
  bottom    : 0;
  left      : 0;
  right     : 0;
  height    : 100%;
  width     : 100%;
  opacity   : 1;
  transition: 1.5s ease;
  background: -webkit-gradient(linear, right top, left top, color-stop(33%, rgb(244, 246, 248, .6)), color-stop(60%, #f4f6f8));
  background: linear-gradient(to left, rgb(244, 246, 248, .6) 33%, #f4f6f8 60%);
}