@import '../core/variables';

pre {
  padding      : 12px;
  overflow     : auto;
  border-radius: 3px;
  margin-bottom: 12px;
}

code {
  padding      : 3px 8px;
  overflow     : auto;
  border-radius: 3px;
}

// responsive view
@media(max-width: $extraSmallBreak) {
  pre {
    width: 93vw;
  }
}