html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
// blockquote,
pre,
abbr,
address,
cite,
// code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
// ol,
// ul,
// li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin           : 0;
  padding          : 0;
  border           : 0;
  outline          : 0;
  // vertical-align: baseline;
  background       : transparent;
}

html {
  box-sizing   : border-box;
  // font-size : 62.5%;
}

// applying box sizing to every element inheriting from HTML
// using this technique from CSS tricks https://css-tricks.com/box-sizing/
// *,
// *:before,
// *:after {
//   box-sizing: border-box;
// }

// This reverts the default font size to 14px
body {
  overflow-x             : hidden;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

// removed bullets from ul and li items if used as navigation elements
nav ul,
menu li {
  list-style: none;
}

hr {
  border        : none;
  color         : #111;
  height        : unset;
  letter-spacing: 1em;
  text-align    : center;
}

hr:before {
  content: '•••';
}

input,
select {
  vertical-align: middle;
}