@import '../core/variables';

// font color
[whiteFontColor] {
  color: var(--white);
}

[primaryFontColor] {
  color: var(--primary);
}

[secondaryFontColor] {
  color: var(--secondary);
}

[warningFontColor] {
  color: var(--warning);
}

[errorFontColor] {
  color: var(--error);
}

[g1FontColor] {
  color: var(--gray-98);
}

[g2FontColor] {
  color: var(--gray-40);
}

[g3FontColor] {
  color: var(--gray-30);
}

[g4FontColor] {
  color: var(--gray-20);
}

[g5FontColor] {
  color: var(--gray-15);
}

[g6FontColor] {
  color: var(--gray-10);
}

[gray1FontColor] {
  color: var(--gray-1);
}

[gray2FontColor] {
  color: var(--gray-2);
}

[gray3FontColor] {
  color: var(--gray-3);
}

[gray4FontColor] {
  color: var(--gray-4);
}

[gray5FontColor] {
  color: var(--gray-5);
}

[gray6FontColor] {
  color: var(--gray-6);
}

[gray7FontColor] {
  color: var(--gray-7);
}

[gray8FontColor] {
  color: var(--gray-8);
}

[gray9FontColor] {
  color: var(--gray-9);
}

[gray10FontColor] {
  color: var(--gray-10);
}

[gray11FontColor] {
  color: var(--gray-11);
}

[gray12FontColor] {
  color: var(--gray-12);
}