// TODO get alternative color from gray colors
$font-weight--semi-bold: 600;
$shade-two: rgb(131,145,152);

.chip-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
	.chip {
		font-weight: $font-weight--semi-bold;
		margin-right: 6px;
		margin-bottom: 6px;
		align-items: center;
		padding: 8px 12px;
		font-size: 1.2rem;
		line-height: 12px;
		border-radius: 4px;
		background-color: rgba($shade-two, 0.15);
	}
}
