[representativeRole] {
  border-radius: 3px;
  font-weight  : 600;
  font-size    : 13px;
  padding      : 5px 12px;
  background   : #f0f0f0;
  background   : var(--gray-3);
  color        : var(--gray-9);
  border       : 1px solid #ececec;
  border       : 1px solid var(--gray-4);
}