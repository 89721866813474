// padding
$spacing-0 : 0px;
$spacing-1 : 3px;
$spacing-2 : 6px;
$spacing-3 : 9px;
$spacing-4 : 12px;
$spacing-5 : 15px;
$spacing-6 : 18px;
$spacing-7 : 24px;
$spacing-8 : 36px;
$spacing-9 : 48px;
$spacing-10: 60px;
$spacing-11: 72px;
$spacing-12: 84px;
$spacing-13: 96px;

// domensions
$page-fs-width : 100%;
$page-l-width  : 1440px;
$page-m-width  : 1180px;
$page-m-s-width: 1140px;
$page-s-width  : 1000px;
$page-e-s-width: 950px;

// radius
$radius: 3px;

// color
$color-1: #6200ee;
$color-2: #018786;
$color-3: #02cecc;
$color-4: #8936ff;
$color-5: #004040;
$color-6: #996057;
$color-7: #6c443d;
$color-8: #3f51b5;

// color
$color-primary: #673ab7;
$color-error  : #f44336;
$color-warn   : #ffd740;
// $color-warn: #ffd752;
// $color-warn: #ffe79a;

// border color
$border-color  : #dddddd;
$border-color-1: #e9e9e9;

// stripe color
$stripe-color-1: #337ab7;
$stripe-color-2: #673ab7;
$stripe-color-3: #6f46b6;

// font color
$font-color-1: #009fdc;
$font-color-2: #673ab7;

// link color
$link-color-1: #009fdc;
$link-color-2: #673ab7;

// background color
$background-color-1: #f3f6f8;
$background-color-2: #673ab7;
$background-color-3: hsl(180, 50%, 50%);

// font-size
$font-size-0 : 10px;
$font-size-1 : 12px;
$font-size-2 : 13px;
$font-size-3 : 14px;
$font-size-4 : 15px;
$font-size-5 : 16px;
$font-size-6 : 18px;
$font-size-7 : 20px;
$font-size-8 : 22px;
$font-size-9 : 24px;
$font-size-10: 25px;
$font-size-11: 30px;
$font-size-12: 35px;
$font-size-13: 40px;
$font-size-14: 45px;
$font-size-15: 50px;

// page breakpoints
$nanoBreak      : 320px;
$microBreak     : 480px;
$extraSmallBreak: 768px;
$smallBreak     : 959px;
$mediumBreak    : 1024px;
$largeBreak     : 1279px;
$extraLargeBreak: 1439px;

// font sizes
$nanoFont       : 12px;
$microFont      : 14px;
$extraSmallFont : 16px;
$smallFont      : 20px;
$mediumFont     : 24px;
$largeFont      : 32px;
$extraLargeFont : 48px;
$megaFont       : 56px;
$hexaFont       : 60px;

// some