@use '@angular/material' as mat;
@import './variables.scss';
@import './grid.scss';
@import '../themes/fusion-default.theme.scss';

.main-nav {
  @extend .body-section;
}
.fs-page {
  max-width: $page-fs-width;
  margin: 0 auto !important;
  float: none !important;
}
.f-page {
  max-width: $page-l-width;
  margin: 0 auto !important;
  float: none !important;
}
.l-page {
  max-width: $page-l-width;
  @extend .body-section;
}
.m-page {
  max-width: $page-m-width;
  @extend .body-section;
}
.m-s-page {
  max-width: $page-m-s-width;
  @extend .body-section;
}
.s-page {
  max-width: $page-s-width;
  @extend .body-section;
}
.e-s-page {
  max-width: $page-e-s-width;
  @extend .body-section;
}
.body-section {
  margin: 0 auto !important;
  float: none !important;
}
.card {
  @extend .card-style;
  // @extend .animation;
}
.card-content-padding {
  @extend .v-padding-5;
  @extend .h-padding-7;
}
.card-header {
  @extend .t-radius;
  @extend .v-padding-3;
  @extend .h-padding-7;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-header-color {
  // background-color: $background-color-2;
  background-color: mat.get-color-from-palette($fusion-default-primary);
}
.card-footer {
  @extend .v-padding-4;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  cursor: pointer;
}
.card-footer:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.dot {
  margin-bottom: 2px;
  height: 4px;
  width: 4px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}
.quote {
  @extend .card;
  @extend .h-padding-8;
  @extend .t-padding-7;
  @extend .b-padding-8;
  .title {
    font-size: 22px;
    @extend .b-padding-3;
  }
  .content {
    border-left: 6px solid rgb(227, 227, 227);
    padding: 10px 24px;
    font-size: 17px;
    color: rgb(79, 79, 79);
    background-color: rgb(249, 249, 249);
    letter-spacing: 0.15em;
  }
}
.break-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.t-card-stripe {
  border-top: 4px solid mat.get-color-from-palette($fusion-default-primary);
}
.l-card-stripe {
  border-left: 4px solid mat.get-color-from-palette($fusion-default-primary);
}
.r-card-stripe {
  border-right: 4px solid mat.get-color-from-palette($fusion-default-primary);
}
.b-card-stripe {
  border-bottom: 4px solid mat.get-color-from-palette($fusion-default-primary);
}
.object-fit_fill {
  object-fit: fill;
}
.object-fit_contain {
  object-fit: contain;
}
.object-fit_cover {
  object-fit: cover;
}
.object-fit_none {
  object-fit: none;
}
.object-fit_scale-down {
  object-fit: scale-down;
}
.animation {
  animation-duration: 1.5s;
  animation-name: slidein;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein {
  from {
    margin-top: 100%;
    width: 100%;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 1;
  }
  50% {
    // font-size: 105%;
    margin-left: -4%;
    width: 110%;
  }
}
.animation-1 {
  animation-duration: 2s;
  animation-name: slidein-1;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}
@keyframes slidein-1 {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}
.animation-2 {
  animation-duration: 2.5s;
  animation-name: slidein-2;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein-2 {
  from {
    margin-left: -100%;
    width: 100%;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 1;
  }
  70% {
    font-size: 110%;
    margin-left: -4%;
    width: 110%;
  }
}
.animation-3 {
  animation-duration: 2.5s;
  animation-name: slidein-3;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein-3 {
  from {
    margin-left: -100%;
    // width: 100%;
    opacity: 0.5;
  }
  to {
    // width: 100%;
    opacity: 1;
  }
  70% {
    // font-size: 110%;
    // margin-left: -4%;
    // width: 110%;
  }
}
.animation-4 {
  animation-duration: 2.5s;
  animation-name: slidein-4;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein-4 {
  from {
    margin-left: 100%;
    // width: 100%;
    opacity: 0.5;
  }
  to {
    // width: 100%;
    opacity: 1;
  }
  70% {
    // font-size: 110%;
    // margin-left: -4%;
    // width: 110%;
  }
}
.animation-5 {
  animation-duration: 2.5s;
  animation-name: slidein-5;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes slidein-5 {
  from {
    margin-top: 100%;
    // width: 100%;
    opacity: 0.5;
    display: none;
  }
  to {
    // width: 100%;
    opacity: 1;
    display: block;
  }
  99% {
    display: none;
    // font-size: 110%;
    // margin-left: -4%;
    // width: 110%;
  }
}
.color-animation {
  animation: colorchange 1.2s;
}
@keyframes colorchange {
  0% {
    background: #eee;
    // color: #eee;
  }
  100% {
    background: white;
    // color: #eee;
  }
}
.card-style {
  @extend .radius;
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}
.card-style:hover {
  box-shadow: 0 5px 5px rgba(10, 16, 20, 0.24), 0 0 5px rgba(10, 16, 20, 0.12);
  transition: box-shadow 0.3s;
}

.link-color {
  color: mat.get-color-from-palette($fusion-default-primary, 600);
}
.link-color-1 {
  color: mat.get-color-from-palette($fusion-default-primary, 600);
}
.link-color-2 {
  color: $link-color-2;
}
div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}
.icon-style {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 36px;
  height: 36px;
  float: right;
  cursor: pointer;
}

// font size
.font-size-0 {
  font-size: $font-size-0;
}
.font-size-1 {
  font-size: $font-size-1;
}
.font-size-2 {
  font-size: $font-size-2;
}
.font-size-3 {
  font-size: $font-size-3;
}
.font-size-4 {
  font-size: $font-size-4;
}
.font-size-5 {
  font-size: $font-size-5;
}
.font-size-6 {
  font-size: $font-size-6;
}
.font-size-7 {
  font-size: $font-size-7;
}
.font-size-8 {
  font-size: $font-size-8;
}
.font-size-9 {
  font-size: $font-size-9;
}
.font-size-10 {
  font-size: $font-size-10;
}
.font-size-11 {
  font-size: $font-size-11;
}
.font-size-12 {
  font-size: $font-size-12;
}
.font-size-13 {
  font-size: $font-size-13;
}
.font-size-14 {
  font-size: $font-size-14;
}
.font-size-15 {
  font-size: $font-size-15;
}

// bashed border
.dashed-border-1 {
  border: 2px dashed rgb(186, 186, 186);
}
.dashed-border-2 {
  border: 2px dashed $stripe-color-3;
}

// border
.border {
  border: 1px solid $border-color;
}
.t-border {
  border-top: 1px solid $border-color;
}
.r-border {
  border-right: 1px solid $border-color;
}
.l-border {
  border-left: 1px solid $border-color;
}
.b-border {
  border-bottom: 1px solid $border-color;
}

.t-border-1 {
  border-top: 1px solid $border-color-1;
}
.r-border-1 {
  border-right: 1px solid $border-color-1;
}
.l-border-1 {
  border-left: 1px solid $border-color-1;
}
.b-border-1 {
  border-bottom: 1px solid $border-color-1;
}

// border
.radius {
  border-radius: $radius;
}
.t-radius {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}
.b-radius {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
.r-radius {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}
.l-radius {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

// divider
.v-divider {
  height: 100%;
  border-left: 1px solid #e9e9e9;
}
.h-divider {
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}
.v-divider-1 {
  height: 100%;
  border-left: 1px solid rgba(101, 101, 101, 0.5);
}
.h-divider-1 {
  width: 100%;
  border-bottom: 1px solid rgba(101, 101, 101, 0.5);
}
.v-divider-2 {
  height: 100%;
  border-left: 1px solid #ffffff;
}
.h-divider-2 {
  width: 100%;
  border-bottom: 1px solid #ffffff;
}

// paddings
.padding-0 {
  padding: $spacing-0;
}
.padding-1 {
  padding: $spacing-1;
}
.padding-2 {
  padding: $spacing-2;
}
.padding-3 {
  padding: $spacing-3;
}
.padding-4 {
  padding: $spacing-4;
}
.padding-5 {
  padding: $spacing-5;
}
.padding-6 {
  padding: $spacing-6;
}
.padding-7 {
  padding: $spacing-7;
}
.padding-8 {
  padding: $spacing-8;
}
.padding-9 {
  padding: $spacing-9;
}
.padding-10 {
  padding: $spacing-10;
}
.padding-11 {
  padding: $spacing-11;
}
.padding-12 {
  padding: $spacing-12;
}
.padding-13 {
  padding: $spacing-13;
}

// b-paddings
.b-padding-0 {
  padding-bottom: $spacing-0;
}
.b-padding-1 {
  padding-bottom: $spacing-1;
}
.b-padding-2 {
  padding-bottom: $spacing-2;
}
.b-padding-3 {
  padding-bottom: $spacing-3;
}
.b-padding-4 {
  padding-bottom: $spacing-4;
}
.b-padding-5 {
  padding-bottom: $spacing-5;
}
.b-padding-6 {
  padding-bottom: $spacing-6;
}
.b-padding-7 {
  padding-bottom: $spacing-7;
}
.b-padding-8 {
  padding-bottom: $spacing-8;
}
.b-padding-9 {
  padding-bottom: $spacing-9;
}
.b-padding-10 {
  padding-bottom: $spacing-10;
}
.b-padding-11 {
  padding-bottom: $spacing-11;
}
.b-padding-12 {
  padding-bottom: $spacing-12;
}
.b-padding-13 {
  padding-bottom: $spacing-13;
}

// t-paddings
.t-padding-0 {
  padding-top: $spacing-0;
}
.t-padding-1 {
  padding-top: $spacing-1;
}
.t-padding-2 {
  padding-top: $spacing-2;
}
.t-padding-3 {
  padding-top: $spacing-3;
}
.t-padding-4 {
  padding-top: $spacing-4;
}
.t-padding-5 {
  padding-top: $spacing-5;
}
.t-padding-6 {
  padding-top: $spacing-6;
}
.t-padding-7 {
  padding-top: $spacing-7;
}
.t-padding-8 {
  padding-top: $spacing-8;
}
.t-padding-9 {
  padding-top: $spacing-9;
}
.t-padding-10 {
  padding-top: $spacing-10;
}
.t-padding-11 {
  padding-top: $spacing-11;
}
.t-padding-12 {
  padding-top: $spacing-12;
}
.t-padding-13 {
  padding-top: $spacing-13;
}

// h-padding
.h-padding-0 {
  padding-left: $spacing-0;
  padding-right: $spacing-0;
}
.h-padding-1 {
  padding-left: $spacing-1;
  padding-right: $spacing-1;
}
.h-padding-2 {
  padding-left: $spacing-2;
  padding-right: $spacing-2;
}
.h-padding-3 {
  padding-left: $spacing-3;
  padding-right: $spacing-3;
}
.h-padding-4 {
  padding-left: $spacing-4;
  padding-right: $spacing-4;
}
.h-padding-5 {
  padding-left: $spacing-5;
  padding-right: $spacing-5;
}
.h-padding-6 {
  padding-left: $spacing-6;
  padding-right: $spacing-6;
}
.h-padding-7 {
  padding-left: $spacing-7;
  padding-right: $spacing-7;
}
.h-padding-8 {
  padding-left: $spacing-8;
  padding-right: $spacing-8;
}
.h-padding-9 {
  padding-left: $spacing-9;
  padding-right: $spacing-9;
}
.h-padding-10 {
  padding-left: $spacing-10;
  padding-right: $spacing-10;
}
.h-padding-11 {
  padding-left: $spacing-11;
  padding-right: $spacing-11;
}
.h-padding-12 {
  padding-left: $spacing-12;
  padding-right: $spacing-12;
}
.h-padding-13 {
  padding-left: $spacing-13;
  padding-right: $spacing-13;
}

// v-padding
.v-padding-0 {
  padding-top: $spacing-0;
  padding-bottom: $spacing-0;
}
.v-padding-1 {
  padding-top: $spacing-1;
  padding-bottom: $spacing-1;
}
.v-padding-2 {
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;
}
.v-padding-3 {
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;
}
.v-padding-4 {
  padding-top: $spacing-4;
  padding-bottom: $spacing-4;
}
.v-padding-5 {
  padding-top: $spacing-5;
  padding-bottom: $spacing-5;
}
.v-padding-6 {
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;
}
.v-padding-7 {
  padding-top: $spacing-7;
  padding-bottom: $spacing-7;
}
.v-padding-8 {
  padding-top: $spacing-8;
  padding-bottom: $spacing-8;
}
.v-padding-9 {
  padding-top: $spacing-9;
  padding-bottom: $spacing-9;
}
.v-padding-10 {
  padding-top: $spacing-10;
  padding-bottom: $spacing-10;
}
.v-padding-11 {
  padding-top: $spacing-11;
  padding-bottom: $spacing-11;
}
.v-padding-12 {
  padding-top: $spacing-12;
  padding-bottom: $spacing-12;
}
.v-padding-13 {
  padding-top: $spacing-13;
  padding-bottom: $spacing-13;
}

// l-paddings
.l-padding-0 {
  padding-left: $spacing-0;
}
.l-padding-1 {
  padding-left: $spacing-1;
}
.l-padding-2 {
  padding-left: $spacing-2;
}
.l-padding-3 {
  padding-left: $spacing-3;
}
.l-padding-4 {
  padding-left: $spacing-4;
}
.l-padding-5 {
  padding-left: $spacing-5;
}
.l-padding-6 {
  padding-left: $spacing-6;
}
.l-padding-7 {
  padding-left: $spacing-7;
}
.l-padding-8 {
  padding-left: $spacing-8;
}
.l-padding-9 {
  padding-left: $spacing-9;
}
.l-padding-10 {
  padding-left: $spacing-10;
}
.l-padding-11 {
  padding-left: $spacing-11;
}
.l-padding-12 {
  padding-left: $spacing-12;
}
.l-padding-13 {
  padding-left: $spacing-13;
}

// r-paddings
.r-padding-0 {
  padding-right: $spacing-0;
}
.r-padding-1 {
  padding-right: $spacing-1;
}
.r-padding-2 {
  padding-right: $spacing-2;
}
.r-padding-3 {
  padding-right: $spacing-3;
}
.r-padding-4 {
  padding-right: $spacing-4;
}
.r-padding-5 {
  padding-right: $spacing-5;
}
.r-padding-6 {
  padding-right: $spacing-6;
}
.r-padding-7 {
  padding-right: $spacing-7;
}
.r-padding-8 {
  padding-right: $spacing-8;
}
.r-padding-9 {
  padding-right: $spacing-9;
}
.r-padding-10 {
  padding-right: $spacing-10;
}
.r-padding-11 {
  padding-right: $spacing-11;
}
.r-padding-12 {
  padding-right: $spacing-12;
}
.r-padding-13 {
  padding-right: $spacing-13;
}

// margins
.margin-0 {
  margin: $spacing-0;
}
.margin-1 {
  margin: $spacing-1;
}
.margin-2 {
  margin: $spacing-2;
}
.margin-3 {
  margin: $spacing-3;
}
.margin-4 {
  margin: $spacing-4;
}
.margin-5 {
  margin: $spacing-5;
}
.margin-6 {
  margin: $spacing-6;
}
.margin-7 {
  margin: $spacing-7;
}
.margin-8 {
  margin: $spacing-8;
}
.margin-9 {
  margin: $spacing-9;
}
.margin-10 {
  margin: $spacing-10;
}
.margin-11 {
  margin: $spacing-11;
}
.margin-12 {
  margin: $spacing-12;
}
.margin-13 {
  margin: $spacing-13;
}

// h-margins
.h-margin-0 {
  margin-left: $spacing-0;
  margin-right: $spacing-0;
}
.h-margin-1 {
  margin-left: $spacing-1;
  margin-right: $spacing-1;
}
.h-margin-2 {
  margin-left: $spacing-2;
  margin-right: $spacing-2;
}
.h-margin-3 {
  margin-left: $spacing-3;
  margin-right: $spacing-3;
}
.h-margin-4 {
  margin-left: $spacing-4;
  margin-right: $spacing-4;
}
.h-margin-5 {
  margin-left: $spacing-5;
  margin-right: $spacing-5;
}
.h-margin-6 {
  margin-left: $spacing-6;
  margin-right: $spacing-6;
}
.h-margin-7 {
  margin-left: $spacing-7;
  margin-right: $spacing-7;
}
.h-margin-8 {
  margin-left: $spacing-8;
  margin-right: $spacing-8;
}
.h-margin-9 {
  margin-left: $spacing-9;
  margin-right: $spacing-9;
}
.h-margin-10 {
  margin-left: $spacing-10;
  margin-right: $spacing-10;
}
.h-margin-11 {
  margin-left: $spacing-11;
  margin-right: $spacing-11;
}
.h-margin-12 {
  margin-left: $spacing-12;
  margin-right: $spacing-12;
}
.h-margin-13 {
  margin-left: $spacing-13;
  margin-right: $spacing-13;
}

// v-padding
.v-margin-0 {
  margin-top: $spacing-0;
  margin-bottom: $spacing-0;
}
.v-margin-1 {
  margin-top: $spacing-1;
  margin-bottom: $spacing-1;
}
.v-margin-2 {
  margin-top: $spacing-2;
  margin-bottom: $spacing-2;
}
.v-margin-3 {
  margin-top: $spacing-3;
  margin-bottom: $spacing-3;
}
.v-margin-4 {
  margin-top: $spacing-4;
  margin-bottom: $spacing-4;
}
.v-margin-5 {
  margin-top: $spacing-5;
  margin-bottom: $spacing-5;
}
.v-margin-6 {
  margin-top: $spacing-6;
  margin-bottom: $spacing-6;
}
.v-margin-7 {
  margin-top: $spacing-7;
  margin-bottom: $spacing-7;
}
.v-margin-8 {
  margin-top: $spacing-8;
  margin-bottom: $spacing-8;
}
.v-margin-9 {
  margin-top: $spacing-9;
  margin-bottom: $spacing-9;
}
.v-margin-10 {
  margin-top: $spacing-10;
  margin-bottom: $spacing-10;
}
.v-margin-11 {
  margin-top: $spacing-11;
  margin-bottom: $spacing-11;
}
.v-margin-12 {
  margin-top: $spacing-12;
  margin-bottom: $spacing-12;
}
.v-margin-13 {
  margin-top: $spacing-13;
  margin-bottom: $spacing-13;
}

// l-margins
.l-margin-0 {
  margin-left: $spacing-0;
}
.l-margin-1 {
  margin-left: $spacing-1;
}
.l-margin-2 {
  margin-left: $spacing-2;
}
.l-margin-3 {
  margin-left: $spacing-3;
}
.l-margin-4 {
  margin-left: $spacing-4;
}
.l-margin-5 {
  margin-left: $spacing-5;
}
.l-margin-6 {
  margin-left: $spacing-6;
}
.l-margin-7 {
  margin-left: $spacing-7;
}
.l-margin-8 {
  margin-left: $spacing-8;
}
.l-margin-9 {
  margin-left: $spacing-9;
}
.l-margin-10 {
  margin-left: $spacing-10;
}
.l-margin-11 {
  margin-left: $spacing-11;
}
.l-margin-12 {
  margin-left: $spacing-12;
}
.l-margin-13 {
  margin-left: $spacing-13;
}

// r-margins
.r-margin-0 {
  margin-right: $spacing-0;
}
.r-margin-1 {
  margin-right: $spacing-1;
}
.r-margin-2 {
  margin-right: $spacing-2;
}
.r-margin-3 {
  margin-right: $spacing-3;
}
.r-margin-4 {
  margin-right: $spacing-4;
}
.r-margin-5 {
  margin-right: $spacing-5;
}
.r-margin-6 {
  margin-right: $spacing-6;
}
.r-margin-7 {
  margin-right: $spacing-7;
}
.r-margin-8 {
  margin-right: $spacing-8;
}
.r-margin-9 {
  margin-right: $spacing-9;
}
.r-margin-10 {
  margin-right: $spacing-10;
}
.r-margin-11 {
  margin-right: $spacing-11;
}
.r-margin-12 {
  margin-right: $spacing-12;
}
.r-margin-13 {
  margin-right: $spacing-13;
}

// t-margins
.t-margin-0 {
  margin-top: $spacing-0;
}
.t-margin-1 {
  margin-top: $spacing-1;
}
.t-margin-2 {
  margin-top: $spacing-2;
}
.t-margin-3 {
  margin-top: $spacing-3;
}
.t-margin-4 {
  margin-top: $spacing-4;
}
.t-margin-5 {
  margin-top: $spacing-5;
}
.t-margin-6 {
  margin-top: $spacing-6;
}
.t-margin-7 {
  margin-top: $spacing-7;
}
.t-margin-8 {
  margin-top: $spacing-8;
}
.t-margin-9 {
  margin-top: $spacing-9;
}
.t-margin-10 {
  margin-top: $spacing-10;
}
.t-margin-11 {
  margin-top: $spacing-11;
}
.t-margin-12 {
  margin-top: $spacing-12;
}
.t-margin-13 {
  margin-top: $spacing-13;
}

// b-margins
.b-margin-0 {
  margin-bottom: $spacing-0;
}
.b-margin-1 {
  margin-bottom: $spacing-1;
}
.b-margin-2 {
  margin-bottom: $spacing-2;
}
.b-margin-3 {
  margin-bottom: $spacing-3;
}
.b-margin-4 {
  margin-bottom: $spacing-4;
}
.b-margin-5 {
  margin-bottom: $spacing-5;
}
.b-margin-6 {
  margin-bottom: $spacing-6;
}
.b-margin-7 {
  margin-bottom: $spacing-7;
}
.b-margin-8 {
  margin-bottom: $spacing-8;
}
.b-margin-9 {
  margin-bottom: $spacing-9;
}
.b-margin-10 {
  margin-bottom: $spacing-10;
}
.b-margin-11 {
  margin-bottom: $spacing-11;
}
.b-margin-12 {
  margin-bottom: $spacing-12;
}
.b-margin-13 {
  margin-bottom: $spacing-13;
}
