@import '../core/variables';

// border
[border] {
  border: 1px solid $border-color1;
}

[errorBorder] {
  border: 1px solid var(--warn);
}

[warnBorder] {
  border: 1px solid var(--warning);
}

[warningBorder] {
  border: 1px solid rgba(230, 186, 127, 1);
}

[topBorder] {
  border-top: 1px solid $border-color1;
}

[bottomBorder] {
  border-bottom: 1px solid $border-color1;
}

[leftBorder] {
  border-left: 1px solid $border-color1;
}

[rightBorder] {
  border-right: 1px solid $border-color1;
}