blockquote {
  line-height   : 24px;
  letter-spacing: 1px;
  border-left   : 4px solid var(--blue);
  margin        : 12px 0px;
  padding       : 12px 16px;
  border-radius : 3px;
  background    : rgba(var(--blueRgb), .2);

  p {
    padding: 0px;
  }
}