[revisionStatus] {
  border-radius: 3px;
  font-weight  : 600;
  font-size    : 13px;
  padding      : 5px 12px;
  background   : var(--gray-3);
  border       : 1px solid var(--gray-4);

  &[live] {
    color: var(--material-palette-secondary-700);
  }

  &[draft] {
    color: var(--warning);
  }

  &[retired] {
    color: var(--gray-9);
  }
}

[chip] {
  font-weight        : 600;
  font-size          : 13px;
  background-color   : var(--gray-6);
  border             : 1px solid var(--gray-7);
  border-radius      : 14.5px;
  text-align         : center;
  padding            : 3px 12px;
  // width           : 75px;

  &[light] {
    background-color: var(--gray-3);
    border          : 1px solid var(--gray-4);
  }

  &[outline] {
    color           : var(--gray-9);
    background-color: var(--white);
    border          : 1px solid var(--gray-4);
  }

  &[live] {
    color: var(--material-palette-secondary-700);
  }

  &[draft] {
    color: var(--warning);
  }

  &[retired] {
    color: var(--gray-9);
  }
  
  &[rejected] {
    color: var(--warn);
  }
}