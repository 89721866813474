@import '../core/variables';

label {
  &.text-quote {
    @extend [border];
    @extend [radius];
    background-color: var(--gray-3);
    overflow        : auto;
    padding         : 1px 2px;
    color           : rgb(var(--warn-rgb), 0.9);
    font-family     : monospace;
  }
}

// responsive view
@media(max-width: $extraSmallBreak) {}