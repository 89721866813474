[stickySection] {
  position        : sticky;
  -webkit-position: sticky;
  top             : 0px;
}

[stickyHeader] {
  position        : sticky;
  -webkit-position: sticky;
  top             : 0px;
  align-self      : flex-end;
}

[stickyFooter] {
  position        : sticky;
  -webkit-position: sticky;
  bottom          : 0px;
  align-self      : flex-end;
}