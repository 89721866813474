:root {
  --success     : #4caf50;
  --warning     : #ff9800;
  --warningColor: #f9efe1;
  --error       : #f44336;
  --info        : #00bcd4;
  --rose        : #ea4c89;
  --blue        : #55acee;
  --darkBlue    : #0066CC;
  --darkBlueRgb : 0, 102, 204;
  --blueRgb     : 85, 172, 238;
  --lightYellow : #FEFCEB;
}