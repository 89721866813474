/* Extend default snow styles*/
.ql-editor:focus {
  outline      : none;
  border-bottom: 2px solid var(--primary);
}

.ql-container {
  box-sizing : border-box;
  font-size  : 1rem;
  height     : 100%;
  margin     : 0px;
  position   : relative;
  font-family: var(--font-family);
}

.ql-editor {
  border   : none;
  /* border: 1px solid #949494; */
}

.ql-snow .ql-editor a {
  text-decoration: none;
}

.ql-snow .ql-editor pre.ql-syntax {
  color           : initial;
  background-color: var(--gray-5);
}

.ql-snow .ql-editor pre {
  margin-bottom: 12px;
}

.ql-snow .ql-editor code {
  font-size       : inherit;
  background-color: var(--gray-5) !important;
}

.ql-editor ol,
.ql-editor ul {
  margin-block-end: 1em;
}

// list
.ql-editor ol,
.ql-editor ul {
  line-height: 26px;
  margin     : 0 0 12px;

  &:last-child {
    padding-bottom: unset;
  }

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: unset;
    }
  }
}

// blockquote styles same as design system
.ql-snow .ql-editor blockquote {
  line-height   : 24px;
  letter-spacing: 1px;
  border-left   : 4px solid var(--blue);
  margin        : 12px 0px;
  padding       : 12px 16px;
  border-radius : 3px;
  background    : rgba(var(--blueRgb), .2);
}

/* typography */
.ql-snow .ql-editor h1,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor h5 {
  font-weight   : 600;
  padding-bottom: 6px;
  font-family   : var(--font-family-header);
}

.ql-snow .ql-editor h1 {
  @extend h1;
}

.ql-snow .ql-editor h2 {
  @extend h2;
}

.ql-snow .ql-editor h3 {
  @extend h3;
}

.ql-snow .ql-editor h4 {
  @extend h4;
}

.ql-snow .ql-editor h5 {
  @extend h5;
}

.ql-snow .ql-picker.ql-header {
  width: 125px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'Section Title 1';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'Section Title 2';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'Section Title 3';
}

/* divider styles */
.ql-divider {
  font-size  : 20px;
  font-weight: 600;
  padding    : unset !important;
}

.ql-divider:before {
  content: "—";
}

/* toolbar position */
.ql-container.ql-snow {
  height: auto;
}

.ql-editor {
  height    : 200px;
  overflow-y: scroll;
}