// image
[image] {
  height  : inherit;
  width   : inherit;
  position: relative;

  img {
    height: 100%;
    width : 100%;
  }
}

[imageOverlay] {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  left            : 0;
  right           : 0;
  height          : 100%;
  width           : 100%;
  opacity         : 0;
  transition      : 0.5s ease;
  background-color: rgba(20, 16, 52, 0.69);
}

[imageOverlayLight] {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  left            : 0;
  right           : 0;
  height          : 100%;
  width           : 100%;
  opacity         : 0;
  transition      : 0.5s ease;
  background-color: rgba(0, 0, 0, .1);
}

[imageOverlayTransparent] {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  left            : 0;
  right           : 0;
  height          : 100%;
  width           : 100%;
  opacity         : 0;
  transition      : 0.5s ease;
  background-color: transparent;
}

[image] [imageOverlay] {
  opacity: 1;
}

[image] [imageOverlayLight] {
  opacity: 1;
}

[image] [imageOverlayTransparent] {
  opacity: 1;
}

// profile pictures
[mediumProfileImage] {
  height  : 112px;
  width   : 112px;
  position: relative;

  img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
    border       : 4px solid #fff;
    box-shadow   : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  }
}

[smallProfileImage] {
  height  : 60px;
  width   : 60px;
  position: relative;

  img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
    border       : 2px solid #fff;
    box-shadow   : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  }
}

[extraSmallProfileImage] {
  height  : 40px;
  width   : 40px;
  position: relative;

  img {
    width           : 100%;
    height          : 100%;
    border-radius   : 50%;
    // border       : 1px solid #fff;
    // box-shadow   : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
    //   0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  }
}

[microProfileImage] {
  height  : 28px;
  width   : 28px;
  position: relative;

  img {
    width           : 100%;
    height          : 100%;
    border-radius   : 50%;
    // border       : 1px solid #fff;
    // box-shadow   : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
    //   0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  }
}

// logo
[largeLogo] {
  height  : 120px;
  width   : 120px;
  position: relative;

  img {
    width     : auto;
    height    : auto;
    max-width : 100%;
    max-height: 100%;
    border    : 1px solid #fff;
  }
}

[logo] {
  height  : 80px;
  width   : 80px;
  position: relative;

  img {
    width     : auto;
    height    : auto;
    max-width : 100%;
    max-height: 100%;
    border    : 1px solid #fff;
  }
}

[smallLogo] {
  height  : 60px;
  width   : 60px;
  position: relative;

  img {
    width     : auto;
    height    : auto;
    max-width : 100%;
    max-height: 100%;
    height    : auto;
  }
}

[extraSmallLogo] {
  height  : 40px;
  width   : 40px;
  position: relative;

  img {
    width     : auto;
    height    : auto;
    max-width : 100%;
    max-height: 100%;
    height    : auto;
  }
}

[imageFit] {
  object-fit: cover;
}

[avatarImages] {
  display  : inline-block;
  transform: scale(-1, 1);

  [extraSmallAvatar] {
    position        : relative;
    display         : inline-block;
    background-color: var(--white);
    border          : 2px solid var(--white);
    box-shadow      : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    overflow     : hidden;
    width        : 40px;

    img {
      width    : 100%;
      display  : block;
      transform: scale(-1, 1);
    }
  }

  [extraSmallAvatar]:not(:first-child) {
    margin-left: -24px;
  }
}