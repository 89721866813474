@import '../core/variables';

// spacing
[pageSpacing] {
  padding: $largeGap;
}

[pageHrSpacing] {
  padding: 0px $largeGap;
}

// page grid
[page] {
  display              : grid;
  grid-gap             : 0px;
  grid-template-columns: 100%; // required to be a sticky container
  grid-template-rows   : auto 1fr auto;
  grid-template-areas  :
    'top-nav'
    'body'
    'footer';

  [topNav] {
    grid-area: top-nav;
    z-index  : 1000;
    @extend [stickyHeader];
  }

  [body] {
    grid-area: body;
  }

  [footer] {
    grid-area: footer;
    z-index  : 2;
  }

  [topNav]+[body] {
    min-height: 85vh;
  }
}

// page breaking points
[extraLargePage] {
  max-width: $extraLargePageWidth;
  @extend .body-centering;
}

[largePage] {
  max-width: $largePageWidth;
  @extend .body-centering;
}

[mediumPage] {
  max-width: $mediumPageWidth;
  @extend .body-centering;
}

[smallPage] {
  max-width: $smallPageWidth;
  @extend .body-centering;
}

[extraSmallPage] {
  max-width: $extraSmallPageWidth;
  @extend .body-centering;
}

[microPage] {
  max-width: $microPageWidth;
  @extend .body-centering;
}

[nanoPage] {
  max-width: $nanoPageWidth;
  @extend .body-centering;
}

.body-centering {
  margin: 0 auto !important;
  float : none !important;
}

// responsive view
@media(max-width: $smallBreak) {
  [pageSpacing] {
    padding: $largeGap $extraSmallGap;
  }

  [pageHrSpacing] {
    padding: 0px $extraSmallGap;
  }
}