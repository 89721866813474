:root {
  --white     : #ffffff;
  --background: #f4f6f8;
  --gray-98   : #f9f9f9;
  --gray-40   : #dae1eb;
  --gray-30   : #9da3a6;
  --gray-20   : #455a76;
  --gray-15   : #273141;

  // updated
  --gray-1 : #f9f9f9;
  --gray-2 : #f3f3f3;
  --gray-3 : #f0f0f0;
  --gray-4 : #ececec;
  --gray-5 : #e9e9e9;
  --gray-6 : #e5e5e5;
  --gray-7 : #dae1eb;
  --gray-8 : #9da3a6;
  --gray-9 : #455a76;
  --gray-10: #273141;
  --gray-11: #121a26;
}