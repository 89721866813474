@mixin create-icon($url) {
    display: inline-block;
    background-color: #888; //default 50% grey to keep consistent with what we've been using;
    -webkit-mask:  url($url) no-repeat 50% 50%;
    mask: url($url) no-repeat 50% 50%;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    //default width and height
    width: 16px;
    height: 16px;
}

.icon-up{
    @include create-icon('/assets/icons/ic-chevron-up.svg');
}

.icon-down{
    @include create-icon('/assets/icons/ic-chevron-down.svg');
}

.icon-previous{
    @include create-icon('/assets/icons/ic-chevron-left.svg');
}

.icon-next{
    @include create-icon('/assets/icons/ic-chevron-right.svg');
}

.icon-arrow-up{
    @include create-icon('/assets/icons/ic-arrow-up.svg');
}

.icon-arrow-down{
    @include create-icon('/assets/icons/ic-arrow-down.svg');
}

.icon-arrow-previous{
    @include create-icon('/assets/icons/ic-arrow-left.svg');
}

.icon-arrow-next{
    @include create-icon('/assets/icons/ic-arrow-right.svg');
}

.icon-alert{
    @include create-icon('/assets/icons/ic-alert.svg');
}

.icon-bookmark{
    @include create-icon('/assets/icons/ic-bookmark.svg');
}

.icon-check{
    @include create-icon('/assets/icons/ic-checkmark.svg');
}

.icon-close{
    @include create-icon('/assets/icons/ic-close.svg');
}

.icon-heart{
    @include create-icon('/assets/icons/ic-heart.svg');
}

.icon-menu{
    @include create-icon('/assets/icons/ic-menu.svg');
}

.icon-profile{
    @include create-icon('/assets/icons/ic-profile.svg');
}

.icon-search{
    @include create-icon('/assets/icons/ic-search.svg');
}

.icon-settings{
    @include create-icon('/assets/icons/ic-settings.svg');
}

.icon-denied{
    @include create-icon('/assets/icons/ic-slash.svg');
}
