/* You can add global styles to this file, and also import other style files */
// @import '~angular-calendar/scss/angular-calendar.scss';
// @import '~flatpickr/dist/flatpickr.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
