[fullWidth] {
  width: 100%;
}

[halfWidth] {
  width: 50%;
}

[thirdWidth] {
  width: 75%;
}