@import '../core/variables';

// radius
[radius] {
  border-radius: $radius;
}

[radiusCircle] {
  border-radius: $radiusCircle;
}

[topRadius] {
  border-top-left-radius : $radius;
  border-top-right-radius: $radius;
}

[bottomRadius] {
  border-bottom-left-radius : $radius;
  border-bottom-right-radius: $radius;
}

[rightRadius] {
  border-top-right-radius   : $radius;
  border-bottom-right-radius: $radius;
}

[leftRadius] {
  border-top-left-radius   : $radius;
  border-bottom-left-radius: $radius;
}