// page sizes
$fullScreenPageWidth: 100%;
$extraLargePageWidth: 1440px;
$largePageWidth     : 1180px;
$mediumPageWidth    : 1140px;
$smallPageWidth     : 1000px;
$extraSmallPageWidth: 950px;
$microPageWidth     : 850px;
$nanoPageWidth      : 750px;

// font sizes
$picoFont       : 10px;
$nanoFont       : 12px;
$microFont      : 14px;
$extraSmallFont : 16px;
$smallFont      : 20px;
$mediumFont     : 24px;
$largeFont      : 32px;
$extraLargeFont : 48px;
$megaFont       : 56px;
$hexaFont       : 60px;

// line heights (font-size * 1.5 = line-height)
$picoLineHeight       : 16px;
$nanoLineHeight       : 18px;
$microLineHeight      : 21px;
$extraSmallLineHeight : 24px;
$smallLineHeight      : 30px;
$mediumLineHeight     : 36px;
$largeLineHeight      : 48px;
$extraLargeLineHeight : 58px;
$megaLineHeight       : 68px;
$hexaLineHeight       : 72px;

// grid gaps
$nanoGap      : 4px;
$microGap     : 8px;
$extraSmallGap: 12px;
$smallGap     : 18px;
$mediumGap    : 24px;
$largeGap     : 32px;
$extraLargeGap: 48px;
$megaGap      : 64px;

// radius
$radius      : 3px;
$radiusCircle: 50px;

// border colors
$border-color1: #e9e9e9;

// page breakpoints
$nanoBreak      : 320px;
$microBreak     : 480px;
$extraSmallBreak: 768px;
$smallBreak     : 959px;
$mediumBreak    : 1023px;
$largeBreak     : 1279px;
$extraLargeBreak: 1439px;

// padding
$spacing-0 : 0px;
$spacing-1 : 3px;
$spacing-2 : 6px;
$spacing-3 : 9px;
$spacing-4 : 12px;
$spacing-5 : 15px;
$spacing-6 : 18px;
$spacing-7 : 24px;
$spacing-8 : 36px;
$spacing-9 : 48px;
$spacing-10: 60px;
$spacing-11: 72px;
$spacing-12: 84px;
$spacing-13: 96px;
