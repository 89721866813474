.rowN {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : repeat(auto-fit, minmax(0px, auto));
}

.row1 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : 1fr;
}

.row2 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : auto 1fr;
  grid-template-areas  :
    'row2__section-one'
    'row2__section-two';

  &__section-one {
    grid-area: row2__section-one;
  }

  &__section-two {
    grid-area: row2__section-two;
  }
}

.row3 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : auto auto 1fr;
  grid-template-areas  :
    'row3__section-one'
    'row3__section-two'
    'row3__section-three';

  &__section-one {
    grid-area: row3__section-one;
  }

  &__section-two {
    grid-area: row3__section-two;
  }

  &__section-three {
    grid-area: row3__section-three;
  }
}

.row4 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : auto auto 1fr auto;
  grid-template-areas  :
    'row4__section-one'
    'row4__section-two'
    'row4__section-three'
    'row4__section-four';

  &__section-one {
    grid-area: row4__section-one;
  }

  &__section-two {
    grid-area: row4__section-two;
  }

  &__section-three {
    grid-area: row4__section-three;
  }

  &__section-four {
    grid-area: row4__section-four;
  }
}

.row5 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : 1fr auto auto auto auto auto;
  grid-template-areas  :
    'row5__section-one'
    'row5__section-two'
    'row5__section-three'
    'row5__section-four'
    'row5__section-five';

  &__section-one {
    grid-area: row5__section-one;
  }

  &__section-two {
    grid-area: row5__section-two;
  }

  &__section-three {
    grid-area: row5__section-three;
  }

  &__section-four {
    grid-area: row5__section-four;
  }

  &__section-five {
    grid-area: row5__section-five;
  }
}

.row6 {
  display              : grid;
  grid-gap             : 18px;
  grid-template-columns: 1fr;
  grid-template-rows   : 1fr auto auto auto auto auto;
  grid-template-areas  :
    'row6__section-one'
    'row6__section-two'
    'row6__section-three'
    'row6__section-four'
    'row6__section-five'
    'row6__section-six';

  &__section-one {
    grid-area: row6__section-one;
  }

  &__section-two {
    grid-area: row6__section-two;
  }

  &__section-three {
    grid-area: row6__section-three;
  }

  &__section-four {
    grid-area: row6__section-four;
  }

  &__section-five {
    grid-area: row6__section-five;
  }

  &__section-six {
    grid-area: row6__section-six;
  }
}