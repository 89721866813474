@import './variables';

body {
  font-size: 16px;
  font-family: var(--font-family);
  background-color: var(--background);
  color: var(--gray-10);
  letter-spacing: 0.3px;
}

.hidden {
  visibility: hidden !important;
}

@media (max-width: $extraSmallBreak) {
  body {
    font-size: 14px;
  }
}
