ul,
ol {
  line-height: 26px;
  margin     : 0 0 12px;

  &:last-child {
    padding-bottom: unset;
  }

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: unset;
    }
  }
}