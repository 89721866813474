@import '../core/variables';

// page grid

[pageBody] {
  display              : grid;
  grid-gap             : 16px;
  grid-template-columns: 1fr;
  grid-template-rows   : auto 1fr;
  grid-template-areas  :
    'page-title'
    'page-content';

  [pageTitle] {
    grid-area: page-title;
  }

  [pageContent] {
    grid-area            : page-content;
    display              : grid;
    grid-gap             : 16px;
    grid-template-columns: 1fr 250px;
    grid-template-rows   : 1fr;
    grid-template-areas  : 'content ad-space';

    [content] {
      grid-area: content;
    }

    [adSpace] {
      grid-area: ad-space;
    }
  }

  [pageContentNoAd] {
    grid-area: page-content;
  }
}

// responsive view
@media screen and (max-width: $smallBreak) {
  [pageBody] [pageContent] {
    grid-template-columns: 1fr;
    grid-template-rows   : 1fr auto;
    grid-template-areas  :
      'content'
      'ad-space';
  }
}