[centering] {
  &[top] {
    display        : inline-flex;
    align-items    : flex-start;
    justify-content: center;
    text-align     : center;
  }

  &[topLeft] {
    display        : inline-flex;
    align-items    : flex-start;
    justify-content: left;
    text-align     : left;
  }

  &[topRight] {
    display        : inline-flex;
    align-items    : flex-start;
    justify-content: flex-end;
    text-align     : right;
  }

  &[center] {
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    text-align     : center;
  }

  &[centerLeft] {
    display        : inline-flex;
    align-items    : center;
    justify-content: left;
    text-align     : left;
  }

  &[centerRight] {
    display        : inline-flex;
    align-items    : center;
    justify-content: flex-end;
    text-align     : right;
  }

  &[bottom] {
    display        : inline-flex;
    align-items    : flex-end;
    justify-content: center;
    text-align     : center;
  }

  &[bottomLeft] {
    display        : inline-flex;
    align-items    : flex-end;
    justify-content: left;
    text-align     : left;
  }

  &[bottomRight] {
    display        : inline-flex;
    align-items    : flex-end;
    justify-content: flex-end;
    text-align     : right;
  }
}