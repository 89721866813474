@charset "UTF-8";
:root {
  --font-family: "Mulish", sans-serif;
  --font-family-header: "Mulish", sans-serif;
  --font-family-topnav: FacitWeb;
}

:root {
  --peach: #dea6af;
  --sky-blue: #8cbcd0;
  --pastel-pink: #e6dbc9;
}

:root {
  --white: #ffffff;
  --background: #f4f6f8;
  --gray-98: #f9f9f9;
  --gray-40: #dae1eb;
  --gray-30: #9da3a6;
  --gray-20: #455a76;
  --gray-15: #273141;
  --gray-1: #f9f9f9;
  --gray-2: #f3f3f3;
  --gray-3: #f0f0f0;
  --gray-4: #ececec;
  --gray-5: #e9e9e9;
  --gray-6: #e5e5e5;
  --gray-7: #dae1eb;
  --gray-8: #9da3a6;
  --gray-9: #455a76;
  --gray-10: #273141;
  --gray-11: #121a26;
}

:root {
  --success: #4caf50;
  --warning: #ff9800;
  --warningColor: #f9efe1;
  --error: #f44336;
  --info: #00bcd4;
  --rose: #ea4c89;
  --blue: #55acee;
  --darkBlue: #0066CC;
  --darkBlueRgb: 0, 102, 204;
  --blueRgb: 85, 172, 238;
  --lightYellow: #FEFCEB;
}

.mat-mdc-form-field-error {
  font-size: 12px;
}

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  /* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
}
.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  font-size: 16px;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  padding: 0px 36px !important;
}

.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  min-width: 135px !important;
}

.custom-button.mat-mdc-button {
  padding: 0px !important;
}

.custom-round-button.mat-mdc-unelevated-button,
.custom-round-button.mat-mdc-raised-button,
.custom-round-button.mat-mdc-outlined-button {
  border-radius: 50px !important;
}

.custom-button.mat-mdc-fab {
  width: 31px !important;
  height: 31px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.custom-button.mat-mdc-fab .mat-button-wrapper {
  padding: 0px !important;
}
.custom-button.mat-mdc-fab .mat-button-wrapper .material-icons {
  font-size: 17px !important;
}

.custom-medium-button.mat-mdc-fab {
  width: 40px !important;
  height: 40px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.custom-medium-button.mat-mdc-fab .mat-button-wrapper {
  padding: 0px !important;
}
.custom-medium-button.mat-mdc-fab .mat-button-wrapper .material-icons {
  font-size: 22px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary);
}

.mat-mdc-tab-header {
  border-bottom: 0.8px solid rgb(var(--primary-rgb), 0.2);
}

.chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}
.chip-list .chip {
  font-weight: 600;
  margin-right: 6px;
  margin-bottom: 6px;
  align-items: center;
  padding: 8px 12px;
  font-size: 1.2rem;
  line-height: 12px;
  border-radius: 4px;
  background-color: rgba(131, 145, 152, 0.15);
}

.mat-mdc-select, .mat-mdc-option {
  font-family: var(--font-family);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}

.mat-toolbar {
  font-family: var(--font-family);
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .ql-snow .ql-editor h1,
.ql-snow .ql-editor .mat-toolbar h1,
.mat-toolbar .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-toolbar .mat-h1,
.mat-toolbar .ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor .mat-toolbar .mat-headline-5,
.mat-toolbar .mat-h1,
.mat-toolbar .mat-headline-5,
.mat-toolbar h2,
.mat-toolbar .ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-toolbar h2,
.mat-toolbar .ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-toolbar .mat-h2,
.mat-toolbar .ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor .mat-toolbar .mat-headline-6,
.mat-toolbar .mat-h2,
.mat-toolbar .mat-headline-6,
.mat-toolbar h3,
.mat-toolbar .ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-toolbar h3,
.mat-toolbar .ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-toolbar .mat-h3,
.mat-toolbar .ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor .mat-toolbar .mat-subtitle-1,
.mat-toolbar .mat-h3,
.mat-toolbar .mat-subtitle-1,
.mat-toolbar h4,
.mat-toolbar .ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-toolbar h4,
.mat-toolbar .ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-toolbar .mat-h4,
.mat-toolbar .ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor .mat-toolbar .mat-body-2,
.mat-toolbar .mat-h4,
.mat-toolbar .mat-body-2,
.mat-toolbar h5,
.mat-toolbar .ql-snow .ql-editor h5,
.ql-snow .ql-editor .mat-toolbar h5,
.mat-toolbar h6,
.mat-toolbar .mat-typography .ql-snow .ql-editor h6,
.mat-typography .ql-snow .ql-editor .mat-toolbar h6 {
  font-family: var(--font-family-header);
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-mdc-list-item,
.mat-list-option {
  font-family: var(--font-family) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile.mat-button-toggle-group-appearance-standard {
  border-radius: 0px 0px 0px 4px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile.mat-button-toggle-group-appearance-standard {
  border-right: solid 1px rgba(0, 0, 0, 0.12) !important;
  border-left: solid 0px rgba(0, 0, 0, 0.12) !important;
  border-top: solid 0px rgba(0, 0, 0, 0.12) !important;
  border-bottom: solid 0px rgba(0, 0, 0, 0.12) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile .mat-button-toggle-label-content {
  line-height: 58px !important;
  padding: 0 28px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.profile-nav.mat-button-toggle-group-appearance-standard {
  border-radius: 4px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.profile-nav .mat-button-toggle-label-content {
  line-height: 58px !important;
  padding: 0 30px !important;
  text-align: left;
}

blockquote {
  line-height: 24px;
  letter-spacing: 1px;
  border-left: 4px solid var(--blue);
  margin: 12px 0px;
  padding: 12px 16px;
  border-radius: 3px;
  background: rgba(var(--blueRgb), 0.2);
}
blockquote p, blockquote .mat-body p, .mat-body blockquote p,
blockquote .mat-subtitle-2 p,
.mat-subtitle-2 blockquote p,
blockquote .mat-typography p,
.mat-typography blockquote p {
  padding: 0px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.ignore) {
  background-color: transparent;
}

.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
  width: 28px;
  height: 28px;
  padding: 2px;
}

@media screen and (max-width: 480px) {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    width: unset !important;
  }
}
@media screen and (max-width: 480px) {
  .mat-stepper-vertical .mat-vertical-stepper-header {
    padding: 12px 6px !important;
  }
  .mat-vertical-content {
    padding: 12px !important;
  }
  .mat-stepper-vertical .mat-vertical-content-container {
    margin-left: 18px !important;
  }
}
.colN {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 0.5fr));
}

.col1 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "col1__section-one col1__section-two";
}
.col1__section-one {
  grid-area: col1__section-one;
}
.col1__section-two {
  grid-area: col1__section-two;
}

.col2, [instructor] {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "col2__section-one col2__section-two";
}
.col2__section-one {
  grid-area: col2__section-one;
}
.col2__section-two {
  grid-area: col2__section-two;
}

.col3 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: "col3__section-one col3__section-two col3__section-three";
}
.col3__section-one {
  grid-area: col3__section-one;
}
.col3__section-two {
  grid-area: col3__section-two;
}
.col3__section-three {
  grid-area: col3__section-three;
}

.col4 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: "col4__section-one col4__section-two col4__section-three col4__section-four";
}
.col4__section-one {
  grid-area: col4__section-one;
}
.col4__section-two {
  grid-area: col4__section-two;
}
.col4__section-three {
  grid-area: col4__section-three;
}
.col4__section-four {
  grid-area: col4__section-four;
}

.rowN {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(0px, auto));
}

.row1 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.row2 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "row2__section-one" "row2__section-two";
}
.row2__section-one {
  grid-area: row2__section-one;
}
.row2__section-two {
  grid-area: row2__section-two;
}

.row3, [instructor] [profile] {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "row3__section-one" "row3__section-two" "row3__section-three";
}
.row3__section-one {
  grid-area: row3__section-one;
}
.row3__section-two {
  grid-area: row3__section-two;
}
.row3__section-three {
  grid-area: row3__section-three;
}

.row4 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "row4__section-one" "row4__section-two" "row4__section-three" "row4__section-four";
}
.row4__section-one {
  grid-area: row4__section-one;
}
.row4__section-two {
  grid-area: row4__section-two;
}
.row4__section-three {
  grid-area: row4__section-three;
}
.row4__section-four {
  grid-area: row4__section-four;
}

.row5 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto auto auto auto;
  grid-template-areas: "row5__section-one" "row5__section-two" "row5__section-three" "row5__section-four" "row5__section-five";
}
.row5__section-one {
  grid-area: row5__section-one;
}
.row5__section-two {
  grid-area: row5__section-two;
}
.row5__section-three {
  grid-area: row5__section-three;
}
.row5__section-four {
  grid-area: row5__section-four;
}
.row5__section-five {
  grid-area: row5__section-five;
}

.row6 {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto auto auto auto;
  grid-template-areas: "row6__section-one" "row6__section-two" "row6__section-three" "row6__section-four" "row6__section-five" "row6__section-six";
}
.row6__section-one {
  grid-area: row6__section-one;
}
.row6__section-two {
  grid-area: row6__section-two;
}
.row6__section-three {
  grid-area: row6__section-three;
}
.row6__section-four {
  grid-area: row6__section-four;
}
.row6__section-five {
  grid-area: row6__section-five;
}
.row6__section-six {
  grid-area: row6__section-six;
}

[sticky] {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0px;
  z-index: 2;
}

[fixedPosition] {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
}

[elevationActive] {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

[elevation]:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

[dotOverflow] {
  display: inline-block;
  width: inherit;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

[image] {
  height: inherit;
  width: inherit;
  position: relative;
}
[image] img {
  height: 100%;
  width: 100%;
}

[profileImage], [instructor] [picture] {
  height: 112px;
  width: 112px;
  position: relative;
}
[profileImage] img, [instructor] [picture] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15), 0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
}

[smallProfileImage] {
  height: 60px;
  width: 60px;
  position: relative;
}
[smallProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

[exSmallProfileImage] {
  height: 35px;
  width: 35px;
  position: relative;
}
[exSmallProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

[logo] {
  height: 80px;
  width: 80px;
  position: relative;
}
[logo] img {
  margin-top: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
}

[smallLogo] {
  height: 60px;
  width: 60px;
  position: relative;
}
[smallLogo] img {
  margin-top: 2px;
  width: 100%;
  height: 100%;
}

[imageFit], [instructor] [picture] img {
  object-fit: cover;
}

[hover] [hoverDisplay] {
  visibility: hidden;
}
[hover] [primaryBgColorHover],
[hover] [secondaryBgColorHover],
[hover] [bgColorHover],
[hover] [g1BgColorHover],
[hover] [g2BgColorHover],
[hover] [g3BgColorHover],
[hover] [g4BgColorHover],
[hover] [g5BgColorHover],
[hover] [g6BgColorHover] {
  background-color: none;
}
[hover]:hover [hoverDisplay] {
  visibility: visible;
}
[hover]:hover [primaryBgColorHover] {
  background-color: var(--primary);
}
[hover]:hover [secondaryBgColorHover] {
  background-color: var(--secondary);
}
[hover]:hover [bgColorHover] {
  background-color: rgba(0, 0, 0, 0.03);
}
[hover]:hover [g1BgColorHover] {
  background-color: var(--gray-98);
}
[hover]:hover [g2BgColorHover] {
  background-color: var(--gray-40);
}
[hover]:hover [g3BgColorHover] {
  background-color: var(--gray-30);
}
[hover]:hover [g4BgColorHover] {
  background-color: var(--gray-20);
}
[hover]:hover [g5BgColorHover] {
  background-color: var(--gray-15);
}
[hover]:hover [g6BgColorHover] {
  background-color: var(--gray-10);
}

[pointer]:hover {
  cursor: pointer;
}

[noPointer]:hover {
  cursor: no-drop !important;
}

[uppercase] {
  text-transform: uppercase;
}

[capitalize] {
  text-transform: capitalize;
}

[primaryBgColor] {
  background-color: var(--primary);
}

[secondaryBgColor] {
  background-color: var(--secondary);
}

[whiteBgColor] {
  background-color: var(--white);
}

[bgColor] {
  background-color: rgba(0, 0, 0, 0.03);
}

[g1BgColor] {
  background-color: var(--gray-98);
}

[g2BgColor] {
  background-color: var(--gray-40);
}

[g3BgColor] {
  background-color: var(--gray-30);
}

[g4BgColor] {
  background-color: var(--gray-20);
}

[g5BgColor] {
  background-color: var(--gray-15);
}

[g6BgColor] {
  background-color: var(--gray-10);
}

[successBgColor] {
  background-color: var(--success-bg);
}

[warningBgColor] {
  background-color: #fffed9;
}

[errorBgColor] {
  background-color: var(--error-bg);
}

[whiteFontColor] {
  color: var(--white);
}

[primaryFontColor] {
  color: var(--primary);
}

[secondaryFontColor] {
  color: var(--secondary);
}

[g1FontColor] {
  color: var(--gray-98);
}

[g2FontColor] {
  color: var(--gray-40);
}

[g3FontColor] {
  color: var(--gray-30);
}

[g4FontColor] {
  color: var(--gray-20);
}

[g5FontColor] {
  color: var(--gray-15);
}

[g6FontColor] {
  color: var(--gray-10);
}

[centering] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

[centeringLeft] {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  vertical-align: middle;
}

[centeringRight] {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
}

[centeringTop] {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

[centeringBottom] {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

[centeringImage] {
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

[centeringText] {
  text-align: center;
}

[alignLeft] {
  text-align: left;
}

[alignRight] {
  text-align: right;
}

[exLight] {
  font-weight: 200;
}

[light] {
  font-weight: 300;
}

[normal] {
  font-weight: 400;
}

[microBold] {
  font-weight: 500;
}

[semiBold], [instructor] [profile] [name] {
  font-weight: 600;
}

[bold] {
  font-weight: 700;
}

[extraBold] {
  font-weight: 800;
}

[microFont] {
  font-size: 10px;
}

[exSmallFont] {
  font-size: 12px;
}

[smallFont] {
  font-size: 15px;
}

[exMediumFont] {
  font-size: 18px;
}

[mediumFont] {
  font-size: 22px;
}

[largeFont] {
  font-size: 28px;
}

[exLargeFont] {
  font-size: 36px;
}

[exxLargeFont] {
  font-size: 48px;
}

[microTitle], [instructor] [profile] [position] {
  font-size: 12px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

[exxSmallTitle] {
  font-size: 14px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

[exSmallTitle] {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

[exMediumTitle] {
  font-size: 22px;
  line-height: 27px;
  font-family: var(--font-family-header);
}

[mediumTitle] {
  font-size: 24px;
  line-height: 36px;
  font-family: var(--font-family-header);
}

[largeTitle] {
  font-size: 28px;
  line-height: 42px;
  font-family: var(--font-family-header);
}

[exLargeTitle] {
  font-size: 36px;
  line-height: 54px;
  font-family: var(--font-family-header);
}

[exxLargeTitle] {
  font-size: 46px;
  line-height: 54px;
  font-family: var(--font-family-header);
}

[exxxLargeTitle] {
  font-size: 56px;
  line-height: 72px;
  font-family: var(--font-family-header);
}

[border], label.text-quote {
  border: 1px solid #e9e9e9;
}

[topBorder] {
  border-top: 1px solid #e9e9e9;
}

[bottomBorder] {
  border-bottom: 1px solid #e9e9e9;
}

[leftBorder] {
  border-left: 1px solid #e9e9e9;
}

[rightBorder] {
  border-right: 1px solid #e9e9e9;
}

[topDivider] {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  border-top: 1px solid #e9e9e9;
}
[topDivider]:first-child {
  margin-top: 0px;
}

[bottomDivider] {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #e9e9e9;
}
[bottomDivider]:last-child {
  margin-bottom: 0px;
}

[leftDivider] {
  height: 100%;
  border-left: 1px solid #e9e9e9;
}
[leftDivider]:first-child {
  margin-left: 0px;
}

[rightDivider] {
  height: 100%;
  border-right: 1px solid #e9e9e9;
}
[rightDivider]:first-child {
  margin-right: 0px;
}

[radius], label.text-quote {
  border-radius: 3px;
}

[tRadius] {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

[bRadius] {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

[rRadius] {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

[lRadius] {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.icon-up {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-chevron-up.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-chevron-up.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-down {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-chevron-down.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-chevron-down.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-previous {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-chevron-left.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-chevron-left.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-next {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-chevron-right.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-chevron-right.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-arrow-up {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-arrow-up.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-arrow-up.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-arrow-down {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-arrow-down.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-arrow-down.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-arrow-previous {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-arrow-left.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-arrow-left.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-arrow-next {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-arrow-right.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-arrow-right.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-alert {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-alert.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-alert.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-bookmark {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-bookmark.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-bookmark.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-check {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-checkmark.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-checkmark.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-close {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-close.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-close.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-heart {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-heart.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-heart.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-menu {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-menu.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-menu.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-profile {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-profile.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-profile.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-search {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-search.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-search.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-settings {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-settings.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-settings.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

.icon-denied {
  display: inline-block;
  background-color: #888;
  -webkit-mask: url("/assets/icons/ic-slash.svg") no-repeat 50% 50%;
  mask: url("/assets/icons/ic-slash.svg") no-repeat 50% 50%;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  width: 16px;
  height: 16px;
}

* {
  box-sizing: border-box;
  outline: 0;
}

.row {
  margin: 0px;
}

.row::after {
  content: "";
  clear: both;
  display: block;
}

[class*=col-] {
  float: left;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}
/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}
/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}
/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #01d4ff);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #e91e63);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0f256e;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #01d4ff;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font-family);
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1,
.mat-typography .ql-snow .ql-editor h1,
.ql-snow .ql-editor .mat-typography h1,
.mat-typography .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-typography .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor .mat-typography .mat-headline-5 {
  font: 400 24px / 32px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2,
.mat-typography .ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-typography h2,
.mat-typography .ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-typography .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor .mat-typography .mat-headline-6 {
  font: 500 20px / 32px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3,
.mat-typography .ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-typography h3,
.mat-typography .ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-typography .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor .mat-typography .mat-subtitle-1 {
  font: 400 16px / 28px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4,
.mat-typography .ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-typography h4,
.mat-typography .ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-typography .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor .mat-typography .mat-body-2,
.mat-typography .mat-body-2 {
  font: 400 15px / 24px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5,
.mat-typography .ql-snow .ql-editor h5,
.ql-snow .ql-editor .mat-typography h5 {
  font: 400 calc(16px * 0.83) / 20px var(--font-family);
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6,
.mat-typography .ql-snow .ql-editor h6 {
  font: 400 calc(16px * 0.67) / 20px var(--font-family);
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 24px var(--font-family);
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 16px / 20px var(--font-family);
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px var(--font-family);
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 112px / 112px var(--font-family);
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 400 56px / 56px var(--font-family);
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 45px / 48px var(--font-family);
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 16px / 20px var(--font-family);
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: var(--font-family);
}

.mat-calendar {
  font-family: var(--font-family);
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 600;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 16px / 20px var(--font-family);
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: var(--font-family);
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .ql-snow .ql-editor h1,
.ql-snow .ql-editor .mat-toolbar h1,
.mat-toolbar .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-toolbar .mat-h1,
.mat-toolbar .ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor .mat-toolbar .mat-headline-5,
.mat-toolbar .mat-h1,
.mat-toolbar .mat-headline-5,
.mat-toolbar h2,
.mat-toolbar .ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-toolbar h2,
.mat-toolbar .ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-toolbar .mat-h2,
.mat-toolbar .ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor .mat-toolbar .mat-headline-6,
.mat-toolbar .mat-h2,
.mat-toolbar .mat-headline-6,
.mat-toolbar h3,
.mat-toolbar .ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-toolbar h3,
.mat-toolbar .ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-toolbar .mat-h3,
.mat-toolbar .ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor .mat-toolbar .mat-subtitle-1,
.mat-toolbar .mat-h3,
.mat-toolbar .mat-subtitle-1,
.mat-toolbar h4,
.mat-toolbar .ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-toolbar h4,
.mat-toolbar .ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-toolbar .mat-h4,
.mat-toolbar .ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor .mat-toolbar .mat-body-2,
.mat-toolbar .mat-h4,
.mat-toolbar .mat-body-2,
.mat-toolbar h5,
.mat-toolbar .ql-snow .ql-editor h5,
.ql-snow .ql-editor .mat-toolbar h5,
.mat-toolbar h6,
.mat-toolbar .mat-typography .ql-snow .ql-editor h6,
.mat-typography .ql-snow .ql-editor .mat-toolbar h6 {
  font: 500 20px / 32px var(--font-family);
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: var(--font-family);
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: var(--font-family);
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(15px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px;
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: var(--font-family);
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: var(--font-family);
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-label-text-font: var(--font-family);
  --mdc-slider-label-label-text-font-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: var(--mdc-typography-overline-font-size, 12px);
  line-height: var(--mdc-typography-overline-line-height, 32px);
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: var(--mdc-typography-overline-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

@media all and (-ms-high-contrast: none) {
  .mat-mdc-list-option .mdc-list-item__start .mdc-checkbox .mdc-checkbox__focus-ring, .mat-mdc-list-option .mdc-list-item__end .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.mat-mdc-list-item .mdc-list-item__primary-text {
  font: 400 15px / 24px var(--font-family);
  letter-spacing: normal;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: var(--font-family);
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, var(--font-family)));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #01d4ff);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #e91e63);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0f256e;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #01d4ff;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0f256e;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(15, 37, 110, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(15, 37, 110, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #01d4ff;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(1, 212, 255, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(1, 212, 255, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e91e63;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(233, 30, 99, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(233, 30, 99, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0f256e);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #0f256e);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0f256e);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(15, 37, 110, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #e91e63);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(15, 37, 110, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(1, 212, 255, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(233, 30, 99, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #01d4ff);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #01d4ff);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(1, 212, 255, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #01d4ff);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(233, 30, 99, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e91e63);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0f256e);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(15, 37, 110, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(1, 212, 255, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(233, 30, 99, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(233, 30, 99, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0f256e);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0f256e;
  --mdc-chip-elevated-disabled-container-color: #0f256e;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #01d4ff;
  --mdc-chip-elevated-disabled-container-color: #01d4ff;
  --mdc-chip-label-text-color: black;
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e91e63;
  --mdc-chip-elevated-disabled-container-color: #e91e63;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0d2166;
  --mdc-switch-selected-handle-color: #0d2166;
  --mdc-switch-selected-hover-state-layer-color: #0d2166;
  --mdc-switch-selected-pressed-state-layer-color: #0d2166;
  --mdc-switch-selected-focus-handle-color: #040d3f;
  --mdc-switch-selected-hover-handle-color: #040d3f;
  --mdc-switch-selected-pressed-handle-color: #040d3f;
  --mdc-switch-selected-focus-track-color: #57669a;
  --mdc-switch-selected-hover-track-color: #57669a;
  --mdc-switch-selected-pressed-track-color: #57669a;
  --mdc-switch-selected-track-color: #57669a;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #01cfff;
  --mdc-switch-selected-handle-color: #01cfff;
  --mdc-switch-selected-hover-state-layer-color: #01cfff;
  --mdc-switch-selected-pressed-state-layer-color: #01cfff;
  --mdc-switch-selected-focus-handle-color: #00b9ff;
  --mdc-switch-selected-hover-handle-color: #00b9ff;
  --mdc-switch-selected-pressed-handle-color: #00b9ff;
  --mdc-switch-selected-focus-track-color: #4de1ff;
  --mdc-switch-selected-hover-track-color: #4de1ff;
  --mdc-switch-selected-pressed-track-color: #4de1ff;
  --mdc-switch-selected-track-color: #4de1ff;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e61a5b;
  --mdc-switch-selected-handle-color: #e61a5b;
  --mdc-switch-selected-hover-state-layer-color: #e61a5b;
  --mdc-switch-selected-pressed-state-layer-color: #e61a5b;
  --mdc-switch-selected-focus-handle-color: #d90a35;
  --mdc-switch-selected-hover-handle-color: #d90a35;
  --mdc-switch-selected-pressed-handle-color: #d90a35;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #0f256e;
  --mdc-radio-selected-hover-icon-color: #0f256e;
  --mdc-radio-selected-icon-color: #0f256e;
  --mdc-radio-selected-pressed-icon-color: #0f256e;
  --mat-mdc-radio-checked-ripple-color: #0f256e;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #01d4ff;
  --mdc-radio-selected-hover-icon-color: #01d4ff;
  --mdc-radio-selected-icon-color: #01d4ff;
  --mdc-radio-selected-pressed-icon-color: #01d4ff;
  --mat-mdc-radio-checked-ripple-color: #01d4ff;
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
  --mat-mdc-radio-checked-ripple-color: #e91e63;
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0f256e;
  --mdc-slider-focus-handle-color: #0f256e;
  --mdc-slider-hover-handle-color: #0f256e;
  --mdc-slider-active-track-color: #0f256e;
  --mdc-slider-inactive-track-color: #0f256e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0f256e;
  --mat-mdc-slider-ripple-color: #0f256e;
  --mat-mdc-slider-hover-ripple-color: rgba(15, 37, 110, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(15, 37, 110, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #01d4ff;
  --mdc-slider-focus-handle-color: #01d4ff;
  --mdc-slider-hover-handle-color: #01d4ff;
  --mdc-slider-active-track-color: #01d4ff;
  --mdc-slider-inactive-track-color: #01d4ff;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #01d4ff;
  --mat-mdc-slider-ripple-color: #01d4ff;
  --mat-mdc-slider-hover-ripple-color: rgba(1, 212, 255, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(1, 212, 255, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #e91e63;
  --mdc-slider-focus-handle-color: #e91e63;
  --mdc-slider-hover-handle-color: #e91e63;
  --mdc-slider-active-track-color: #e91e63;
  --mdc-slider-inactive-track-color: #e91e63;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e91e63;
  --mat-mdc-slider-ripple-color: #e91e63;
  --mat-mdc-slider-hover-ripple-color: rgba(233, 30, 99, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(233, 30, 99, 0.2);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0f256e);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-list-item-interactive::before {
  background: black;
}
.mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #0f256e;
  opacity: 0.08;
}
.mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}

.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0f256e);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0f256e);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0f256e;
  --mdc-checkbox-selected-hover-icon-color: #0f256e;
  --mdc-checkbox-selected-icon-color: #0f256e;
  --mdc-checkbox-selected-pressed-icon-color: #0f256e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #01d4ff;
  --mdc-checkbox-selected-hover-icon-color: #01d4ff;
  --mdc-checkbox-selected-icon-color: #01d4ff;
  --mdc-checkbox-selected-pressed-icon-color: #01d4ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0f256e;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0f256e;
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #0f256e;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #01d4ff;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #01d4ff;
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #01d4ff;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e91e63;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #e91e63;
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e91e63;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #0f256e;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #01d4ff;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #e91e63;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0f256e;
  --mdc-checkbox-selected-hover-icon-color: #0f256e;
  --mdc-checkbox-selected-icon-color: #0f256e;
  --mdc-checkbox-selected-pressed-icon-color: #0f256e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(15, 37, 110, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #0f256e;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #01d4ff;
  --mdc-checkbox-selected-hover-icon-color: #01d4ff;
  --mdc-checkbox-selected-icon-color: #01d4ff;
  --mdc-checkbox-selected-pressed-icon-color: #01d4ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(1, 212, 255, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #01d4ff;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(233, 30, 99, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e91e63;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0f256e;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #01d4ff;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e91e63;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0f256e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #01d4ff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e91e63;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0f256e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #01d4ff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e91e63;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0f256e;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #01d4ff;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e91e63;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0f256e;
  --mat-mdc-button-ripple-color: rgba(15, 37, 110, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #01d4ff;
  --mat-mdc-button-ripple-color: rgba(1, 212, 255, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e91e63;
  --mat-mdc-button-ripple-color: rgba(233, 30, 99, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0f256e;
  --mat-mdc-button-ripple-color: rgba(15, 37, 110, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #01d4ff;
  --mat-mdc-button-ripple-color: rgba(1, 212, 255, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e91e63;
  --mat-mdc-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0f256e;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #01d4ff;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e91e63;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0f256e;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #01d4ff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #e91e63;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #01d4ff;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(15, 37, 110, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0f256e;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #01d4ff;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #e91e63;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #0f256e;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #01d4ff;
  color: black;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #e91e63;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(15, 37, 110, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(15, 37, 110, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(15, 37, 110, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #0f256e;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(15, 37, 110, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(15, 37, 110, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(15, 37, 110, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(1, 212, 255, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(1, 212, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(1, 212, 255, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #01d4ff;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(1, 212, 255, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(1, 212, 255, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(1, 212, 255, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #0f256e;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #01d4ff;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e91e63;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #0f256e;
}
.mat-icon.mat-accent {
  color: #01d4ff;
}
.mat-icon.mat-warn {
  color: #e91e63;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #0f256e;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #01d4ff;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e91e63;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e91e63;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #0f256e;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #01d4ff;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #e91e63;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
  margin: auto 9px 5px 9px !important;
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  z-index: 1000;
}

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label {
  white-space: normal !important;
}

.mat-h5,
.mat-typography h5,
.mat-typography .ql-snow .ql-editor h5,
.ql-snow .ql-editor .mat-typography h5 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  font-family: var(--font-family-header);
}

.mat-h6,
.mat-typography h6,
.mat-typography .ql-snow .ql-editor h6 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  font-family: var(--font-family-header);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  width: 130px;
}

.mat-mdc-menu-panel {
  min-height: unset !important;
}

.fs-page {
  max-width: 100%;
  margin: 0 auto !important;
  float: none !important;
}

.f-page {
  max-width: 1440px;
  margin: 0 auto !important;
  float: none !important;
}

.l-page {
  max-width: 1440px;
}

.m-page {
  max-width: 1180px;
}

.m-s-page {
  max-width: 1140px;
}

.s-page {
  max-width: 1000px;
}

.e-s-page {
  max-width: 950px;
}

.body-section, .main-nav, .l-page, .m-page, .m-s-page, .s-page, .e-s-page {
  margin: 0 auto !important;
  float: none !important;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
}

.card-header-color {
  background-color: #0f256e;
}

.card-footer {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  cursor: pointer;
}

.card-footer:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dot {
  margin-bottom: 2px;
  height: 4px;
  width: 4px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.quote .title {
  font-size: 22px;
}
.quote .content {
  border-left: 6px solid rgb(227, 227, 227);
  padding: 10px 24px;
  font-size: 17px;
  color: rgb(79, 79, 79);
  background-color: rgb(249, 249, 249);
  letter-spacing: 0.15em;
}

.break-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.t-card-stripe {
  border-top: 4px solid #0f256e;
}

.l-card-stripe {
  border-left: 4px solid #0f256e;
}

.r-card-stripe {
  border-right: 4px solid #0f256e;
}

.b-card-stripe {
  border-bottom: 4px solid #0f256e;
}

.object-fit_fill {
  object-fit: fill;
}

.object-fit_contain {
  object-fit: contain;
}

.object-fit_cover {
  object-fit: cover;
}

.object-fit_none {
  object-fit: none;
}

.object-fit_scale-down {
  object-fit: scale-down;
}

.animation {
  animation-duration: 1.5s;
  animation-name: slidein;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    margin-top: 100%;
    width: 100%;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 1;
  }
  50% {
    margin-left: -4%;
    width: 110%;
  }
}
.animation-1 {
  animation-duration: 2s;
  animation-name: slidein-1;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}

@keyframes slidein-1 {
  from {
    transform: scale(0.3);
  }
  to {
    transform: scale(1);
  }
}
.animation-2 {
  animation-duration: 2.5s;
  animation-name: slidein-2;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slidein-2 {
  from {
    margin-left: -100%;
    width: 100%;
    opacity: 0.5;
  }
  to {
    width: 100%;
    opacity: 1;
  }
  70% {
    font-size: 110%;
    margin-left: -4%;
    width: 110%;
  }
}
.animation-3 {
  animation-duration: 2.5s;
  animation-name: slidein-3;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slidein-3 {
  from {
    margin-left: -100%;
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.animation-4 {
  animation-duration: 2.5s;
  animation-name: slidein-4;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slidein-4 {
  from {
    margin-left: 100%;
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.animation-5 {
  animation-duration: 2.5s;
  animation-name: slidein-5;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slidein-5 {
  from {
    margin-top: 100%;
    opacity: 0.5;
    display: none;
  }
  to {
    opacity: 1;
    display: block;
  }
  99% {
    display: none;
  }
}
.color-animation {
  animation: colorchange 1.2s;
}

@keyframes colorchange {
  0% {
    background: #eee;
  }
  100% {
    background: white;
  }
}
.card-style, .card, .quote {
  background-color: #ffffff;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}

.card-style:hover, .card:hover, .quote:hover {
  box-shadow: 0 5px 5px rgba(10, 16, 20, 0.24), 0 0 5px rgba(10, 16, 20, 0.12);
  transition: box-shadow 0.3s;
}

.link-color {
  color: #0d2166;
}

.link-color-1 {
  color: #0d2166;
}

.link-color-2 {
  color: #673ab7;
}

div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}

.icon-style {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 36px;
  height: 36px;
  float: right;
  cursor: pointer;
}

.font-size-0 {
  font-size: 10px;
}

.font-size-1 {
  font-size: 12px;
}

.font-size-2 {
  font-size: 13px;
}

.font-size-3 {
  font-size: 14px;
}

.font-size-4 {
  font-size: 15px;
}

.font-size-5 {
  font-size: 16px;
}

.font-size-6 {
  font-size: 18px;
}

.font-size-7 {
  font-size: 20px;
}

.font-size-8 {
  font-size: 22px;
}

.font-size-9 {
  font-size: 24px;
}

.font-size-10 {
  font-size: 25px;
}

.font-size-11 {
  font-size: 30px;
}

.font-size-12 {
  font-size: 35px;
}

.font-size-13 {
  font-size: 40px;
}

.font-size-14 {
  font-size: 45px;
}

.font-size-15 {
  font-size: 50px;
}

.dashed-border-1 {
  border: 2px dashed rgb(186, 186, 186);
}

.dashed-border-2 {
  border: 2px dashed #6f46b6;
}

.border {
  border: 1px solid #dddddd;
}

.t-border {
  border-top: 1px solid #dddddd;
}

.r-border {
  border-right: 1px solid #dddddd;
}

.l-border {
  border-left: 1px solid #dddddd;
}

.b-border {
  border-bottom: 1px solid #dddddd;
}

.t-border-1 {
  border-top: 1px solid #e9e9e9;
}

.r-border-1 {
  border-right: 1px solid #e9e9e9;
}

.l-border-1 {
  border-left: 1px solid #e9e9e9;
}

.b-border-1 {
  border-bottom: 1px solid #e9e9e9;
}

.radius, .card-style, .card, .quote {
  border-radius: 3px;
}

.t-radius, .card-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.b-radius {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.r-radius {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.l-radius {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.v-divider {
  height: 100%;
  border-left: 1px solid #e9e9e9;
}

.h-divider {
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}

.v-divider-1 {
  height: 100%;
  border-left: 1px solid rgba(101, 101, 101, 0.5);
}

.h-divider-1 {
  width: 100%;
  border-bottom: 1px solid rgba(101, 101, 101, 0.5);
}

.v-divider-2 {
  height: 100%;
  border-left: 1px solid #ffffff;
}

.h-divider-2 {
  width: 100%;
  border-bottom: 1px solid #ffffff;
}

.padding-0 {
  padding: 0px;
}

.padding-1 {
  padding: 3px;
}

.padding-2 {
  padding: 6px;
}

.padding-3 {
  padding: 9px;
}

.padding-4 {
  padding: 12px;
}

.padding-5 {
  padding: 15px;
}

.padding-6 {
  padding: 18px;
}

.padding-7 {
  padding: 24px;
}

.padding-8 {
  padding: 36px;
}

.padding-9 {
  padding: 48px;
}

.padding-10 {
  padding: 60px;
}

.padding-11 {
  padding: 72px;
}

.padding-12 {
  padding: 84px;
}

.padding-13 {
  padding: 96px;
}

.b-padding-0 {
  padding-bottom: 0px;
}

.b-padding-1 {
  padding-bottom: 3px;
}

.b-padding-2 {
  padding-bottom: 6px;
}

.b-padding-3, .quote .title {
  padding-bottom: 9px;
}

.b-padding-4 {
  padding-bottom: 12px;
}

.b-padding-5 {
  padding-bottom: 15px;
}

.b-padding-6, [bPageSpacing] {
  padding-bottom: 18px;
}

.b-padding-7 {
  padding-bottom: 24px;
}

.b-padding-8, .quote {
  padding-bottom: 36px;
}

.b-padding-9, [vPageSpacing] {
  padding-bottom: 48px;
}

.b-padding-10 {
  padding-bottom: 60px;
}

.b-padding-11 {
  padding-bottom: 72px;
}

.b-padding-12 {
  padding-bottom: 84px;
}

.b-padding-13 {
  padding-bottom: 96px;
}

.t-padding-0 {
  padding-top: 0px;
}

.t-padding-1 {
  padding-top: 3px;
}

.t-padding-2 {
  padding-top: 6px;
}

.t-padding-3 {
  padding-top: 9px;
}

.t-padding-4 {
  padding-top: 12px;
}

.t-padding-5 {
  padding-top: 15px;
}

.t-padding-6, [tPageSpacing] {
  padding-top: 18px;
}

.t-padding-7, [vPageSpacing], .quote {
  padding-top: 24px;
}

.t-padding-8 {
  padding-top: 36px;
}

.t-padding-9 {
  padding-top: 48px;
}

.t-padding-10 {
  padding-top: 60px;
}

.t-padding-11 {
  padding-top: 72px;
}

.t-padding-12 {
  padding-top: 84px;
}

.t-padding-13 {
  padding-top: 96px;
}

.h-padding-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.h-padding-1 {
  padding-left: 3px;
  padding-right: 3px;
}

.h-padding-2 {
  padding-left: 6px;
  padding-right: 6px;
}

.h-padding-3 {
  padding-left: 9px;
  padding-right: 9px;
}

.h-padding-4 {
  padding-left: 12px;
  padding-right: 12px;
}

.h-padding-5 {
  padding-left: 15px;
  padding-right: 15px;
}

.h-padding-6 {
  padding-left: 18px;
  padding-right: 18px;
}

.h-padding-7, [cardHrSpacing], [cardSpacing], [card][cardHrSpacing], [card][cardSpacing], .card-content-padding, .card-header {
  padding-left: 24px;
  padding-right: 24px;
}

.h-padding-8, .quote {
  padding-left: 36px;
  padding-right: 36px;
}

.h-padding-9 {
  padding-left: 48px;
  padding-right: 48px;
}

.h-padding-10, [cardExSpacing], [card][cardExSpacing] {
  padding-left: 60px;
  padding-right: 60px;
}

.h-padding-11 {
  padding-left: 72px;
  padding-right: 72px;
}

.h-padding-12 {
  padding-left: 84px;
  padding-right: 84px;
}

.h-padding-13 {
  padding-left: 96px;
  padding-right: 96px;
}

.v-padding-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.v-padding-1 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-padding-2 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.v-padding-3, .card-header {
  padding-top: 9px;
  padding-bottom: 9px;
}

.v-padding-4, .card-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}

.v-padding-5, [cardSpacing], [card][cardSpacing], .card-content-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-padding-6 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.v-padding-7, [cardVrSpacing] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.v-padding-8, [cardExSpacing], [card][cardExSpacing] {
  padding-top: 36px;
  padding-bottom: 36px;
}

.v-padding-9 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.v-padding-10 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.v-padding-11 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.v-padding-12 {
  padding-top: 84px;
  padding-bottom: 84px;
}

.v-padding-13 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.l-padding-0 {
  padding-left: 0px;
}

.l-padding-1 {
  padding-left: 3px;
}

.l-padding-2 {
  padding-left: 6px;
}

.l-padding-3, [hPageSpacing] {
  padding-left: 9px;
}

.l-padding-4 {
  padding-left: 12px;
}

.l-padding-5 {
  padding-left: 15px;
}

.l-padding-6 {
  padding-left: 18px;
}

.l-padding-7 {
  padding-left: 24px;
}

.l-padding-8 {
  padding-left: 36px;
}

.l-padding-9 {
  padding-left: 48px;
}

.l-padding-10 {
  padding-left: 60px;
}

.l-padding-11 {
  padding-left: 72px;
}

.l-padding-12 {
  padding-left: 84px;
}

.l-padding-13 {
  padding-left: 96px;
}

.r-padding-0 {
  padding-right: 0px;
}

.r-padding-1 {
  padding-right: 3px;
}

.r-padding-2 {
  padding-right: 6px;
}

.r-padding-3, [hPageSpacing] {
  padding-right: 9px;
}

.r-padding-4 {
  padding-right: 12px;
}

.r-padding-5 {
  padding-right: 15px;
}

.r-padding-6 {
  padding-right: 18px;
}

.r-padding-7 {
  padding-right: 24px;
}

.r-padding-8 {
  padding-right: 36px;
}

.r-padding-9 {
  padding-right: 48px;
}

.r-padding-10 {
  padding-right: 60px;
}

.r-padding-11 {
  padding-right: 72px;
}

.r-padding-12 {
  padding-right: 84px;
}

.r-padding-13 {
  padding-right: 96px;
}

.margin-0 {
  margin: 0px;
}

.margin-1 {
  margin: 3px;
}

.margin-2 {
  margin: 6px;
}

.margin-3 {
  margin: 9px;
}

.margin-4 {
  margin: 12px;
}

.margin-5 {
  margin: 15px;
}

.margin-6 {
  margin: 18px;
}

.margin-7 {
  margin: 24px;
}

.margin-8 {
  margin: 36px;
}

.margin-9 {
  margin: 48px;
}

.margin-10 {
  margin: 60px;
}

.margin-11 {
  margin: 72px;
}

.margin-12 {
  margin: 84px;
}

.margin-13 {
  margin: 96px;
}

.h-margin-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.h-margin-1 {
  margin-left: 3px;
  margin-right: 3px;
}

.h-margin-2 {
  margin-left: 6px;
  margin-right: 6px;
}

.h-margin-3 {
  margin-left: 9px;
  margin-right: 9px;
}

.h-margin-4 {
  margin-left: 12px;
  margin-right: 12px;
}

.h-margin-5 {
  margin-left: 15px;
  margin-right: 15px;
}

.h-margin-6 {
  margin-left: 18px;
  margin-right: 18px;
}

.h-margin-7 {
  margin-left: 24px;
  margin-right: 24px;
}

.h-margin-8 {
  margin-left: 36px;
  margin-right: 36px;
}

.h-margin-9 {
  margin-left: 48px;
  margin-right: 48px;
}

.h-margin-10 {
  margin-left: 60px;
  margin-right: 60px;
}

.h-margin-11 {
  margin-left: 72px;
  margin-right: 72px;
}

.h-margin-12 {
  margin-left: 84px;
  margin-right: 84px;
}

.h-margin-13 {
  margin-left: 96px;
  margin-right: 96px;
}

.v-margin-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-margin-1 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.v-margin-2 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.v-margin-3 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.v-margin-4 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.v-margin-5 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.v-margin-6 {
  margin-top: 18px;
  margin-bottom: 18px;
}

.v-margin-7 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.v-margin-8 {
  margin-top: 36px;
  margin-bottom: 36px;
}

.v-margin-9 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.v-margin-10 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.v-margin-11 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.v-margin-12 {
  margin-top: 84px;
  margin-bottom: 84px;
}

.v-margin-13 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.l-margin-0 {
  margin-left: 0px;
}

.l-margin-1 {
  margin-left: 3px;
}

.l-margin-2 {
  margin-left: 6px;
}

.l-margin-3 {
  margin-left: 9px;
}

.l-margin-4 {
  margin-left: 12px;
}

.l-margin-5 {
  margin-left: 15px;
}

.l-margin-6 {
  margin-left: 18px;
}

.l-margin-7 {
  margin-left: 24px;
}

.l-margin-8 {
  margin-left: 36px;
}

.l-margin-9 {
  margin-left: 48px;
}

.l-margin-10 {
  margin-left: 60px;
}

.l-margin-11 {
  margin-left: 72px;
}

.l-margin-12 {
  margin-left: 84px;
}

.l-margin-13 {
  margin-left: 96px;
}

.r-margin-0 {
  margin-right: 0px;
}

.r-margin-1 {
  margin-right: 3px;
}

.r-margin-2 {
  margin-right: 6px;
}

.r-margin-3 {
  margin-right: 9px;
}

.r-margin-4 {
  margin-right: 12px;
}

.r-margin-5 {
  margin-right: 15px;
}

.r-margin-6 {
  margin-right: 18px;
}

.r-margin-7 {
  margin-right: 24px;
}

.r-margin-8 {
  margin-right: 36px;
}

.r-margin-9 {
  margin-right: 48px;
}

.r-margin-10 {
  margin-right: 60px;
}

.r-margin-11 {
  margin-right: 72px;
}

.r-margin-12 {
  margin-right: 84px;
}

.r-margin-13 {
  margin-right: 96px;
}

.t-margin-0 {
  margin-top: 0px;
}

.t-margin-1 {
  margin-top: 3px;
}

.t-margin-2 {
  margin-top: 6px;
}

.t-margin-3 {
  margin-top: 9px;
}

.t-margin-4 {
  margin-top: 12px;
}

.t-margin-5 {
  margin-top: 15px;
}

.t-margin-6 {
  margin-top: 18px;
}

.t-margin-7 {
  margin-top: 24px;
}

.t-margin-8 {
  margin-top: 36px;
}

.t-margin-9 {
  margin-top: 48px;
}

.t-margin-10 {
  margin-top: 60px;
}

.t-margin-11 {
  margin-top: 72px;
}

.t-margin-12 {
  margin-top: 84px;
}

.t-margin-13 {
  margin-top: 96px;
}

.b-margin-0 {
  margin-bottom: 0px;
}

.b-margin-1 {
  margin-bottom: 3px;
}

.b-margin-2 {
  margin-bottom: 6px;
}

.b-margin-3 {
  margin-bottom: 9px;
}

.b-margin-4 {
  margin-bottom: 12px;
}

.b-margin-5 {
  margin-bottom: 15px;
}

.b-margin-6 {
  margin-bottom: 18px;
}

.b-margin-7 {
  margin-bottom: 24px;
}

.b-margin-8 {
  margin-bottom: 36px;
}

.b-margin-9 {
  margin-bottom: 48px;
}

.b-margin-10 {
  margin-bottom: 60px;
}

.b-margin-11 {
  margin-bottom: 72px;
}

.b-margin-12 {
  margin-bottom: 84px;
}

.b-margin-13 {
  margin-bottom: 96px;
}

html,
body,
div,
span,
object,
iframe,
h1,
.ql-snow .ql-editor h1,
.ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor .mat-typography h1,
.ql-snow .ql-editor .mat-typography .mat-h1,
.ql-snow .ql-editor .mat-typography .mat-headline-5,
.mat-typography .ql-snow .ql-editor h1,
.mat-typography .ql-snow .ql-editor h1,
.mat-typography .ql-snow .ql-editor .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5,
.mat-typography .ql-snow .ql-editor .mat-typography h1,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-headline-5,
.mat-typography .ql-snow .ql-editor .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5,
.mat-h1,
.mat-headline-5,
.mat-typography h1,
.mat-typography .ql-snow .ql-editor h1,
.ql-snow .ql-editor .mat-typography h1,
.mat-typography .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-typography .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor .mat-typography .mat-headline-5,
.ql-snow .ql-editor .mat-typography h1,
.ql-snow .ql-editor .mat-typography .mat-h1,
.ql-snow .ql-editor .mat-typography .mat-headline-5,
h2,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor .mat-typography h2,
.ql-snow .ql-editor .mat-typography .mat-h2,
.ql-snow .ql-editor .mat-typography .mat-headline-6,
.mat-typography .ql-snow .ql-editor h2,
.mat-typography .ql-snow .ql-editor h2,
.mat-typography .ql-snow .ql-editor .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.mat-typography .ql-snow .ql-editor .mat-typography h2,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-headline-6,
.mat-typography .ql-snow .ql-editor .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.mat-h2,
.mat-headline-6,
.mat-typography h2,
.mat-typography .ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-typography h2,
.mat-typography .ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-typography .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor .mat-typography .mat-headline-6,
.ql-snow .ql-editor .mat-typography h2,
.ql-snow .ql-editor .mat-typography .mat-h2,
.ql-snow .ql-editor .mat-typography .mat-headline-6,
h3,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor .mat-typography h3,
.ql-snow .ql-editor .mat-typography .mat-h3,
.ql-snow .ql-editor .mat-typography .mat-subtitle-1,
.mat-typography .ql-snow .ql-editor h3,
.mat-typography .ql-snow .ql-editor h3,
.mat-typography .ql-snow .ql-editor .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.mat-typography .ql-snow .ql-editor .mat-typography h3,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-subtitle-1,
.mat-typography .ql-snow .ql-editor .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.mat-h3,
.mat-subtitle-1,
.mat-typography h3,
.mat-typography .ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-typography h3,
.mat-typography .ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-typography .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor .mat-typography .mat-subtitle-1,
.ql-snow .ql-editor .mat-typography h3,
.ql-snow .ql-editor .mat-typography .mat-h3,
.ql-snow .ql-editor .mat-typography .mat-subtitle-1,
h4,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor .mat-typography h4,
.ql-snow .ql-editor .mat-typography .mat-h4,
.ql-snow .ql-editor .mat-typography .mat-body-2,
.mat-typography .ql-snow .ql-editor h4,
.mat-typography .ql-snow .ql-editor h4,
.mat-typography .ql-snow .ql-editor .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.mat-typography .ql-snow .ql-editor .mat-typography h4,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-typography .mat-body-2,
.mat-typography .ql-snow .ql-editor .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.mat-h4,
.mat-body-2,
.mat-typography h4,
.mat-typography .ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-typography h4,
.mat-typography .ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-typography .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor .mat-typography .mat-body-2,
.ql-snow .ql-editor .mat-typography h4,
.ql-snow .ql-editor .mat-typography .mat-h4,
.ql-snow .ql-editor .mat-typography .mat-body-2,
h5,
.ql-snow .ql-editor h5,
.mat-typography .ql-snow .ql-editor h5,
.mat-typography .ql-snow .ql-editor h5,
h6,
.mat-typography .ql-snow .ql-editor h6,
p,
.mat-body p,
.mat-subtitle-2 p,
.mat-typography p,
pre,
abbr,
address,
cite,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

html {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul,
menu li {
  list-style: none;
}

hr {
  border: none;
  color: #111;
  height: unset;
  letter-spacing: 1em;
  text-align: center;
}

hr:before {
  content: "•••";
}

input,
select {
  vertical-align: middle;
}

h1, .ql-snow .ql-editor h1, .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-headline-5, .mat-typography .ql-snow .ql-editor h1, .mat-typography .ql-snow .ql-editor .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5, .mat-h1,
.mat-headline-5,
.mat-typography h1,
h2,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-headline-6,
.mat-typography .ql-snow .ql-editor h2,
.mat-typography .ql-snow .ql-editor .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.mat-h2,
.mat-headline-6,
.mat-typography h2,
h3,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-subtitle-1,
.mat-typography .ql-snow .ql-editor h3,
.mat-typography .ql-snow .ql-editor .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.mat-h3,
.mat-subtitle-1,
.mat-typography h3,
h4,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-body-2,
.mat-typography .ql-snow .ql-editor h4,
.mat-typography .ql-snow .ql-editor .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.mat-h4,
.mat-body-2,
.mat-typography h4,
h5,
.ql-snow .ql-editor h5,
.mat-typography .ql-snow .ql-editor h5,
h6,
.mat-typography .ql-snow .ql-editor h6 {
  font-family: var(--font-family-header);
  font-weight: 600;
  padding-bottom: 6px;
}

h1, .ql-snow .ql-editor h1, .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-headline-5, .mat-typography .ql-snow .ql-editor h1, .mat-typography .ql-snow .ql-editor .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5, .mat-h1,
.mat-headline-5,
.mat-typography h1 {
  font-size: 36px;
  line-height: 54px;
  color: var(--primary);
}
h1:not(:first-child), .ql-snow .ql-editor h1:not(:first-child), .ql-snow .ql-editor .mat-h1:not(:first-child),
.ql-snow .ql-editor .mat-headline-5:not(:first-child), .mat-h1:not(:first-child),
.mat-headline-5:not(:first-child) {
  padding-top: 12px;
}

h2, .ql-snow .ql-editor h2, .ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-headline-6, .mat-typography .ql-snow .ql-editor h2, .mat-typography .ql-snow .ql-editor .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6, .mat-h2,
.mat-headline-6,
.mat-typography h2 {
  font-size: 28px;
  line-height: 42px;
}
h2:not(:first-child), .ql-snow .ql-editor h2:not(:first-child), .ql-snow .ql-editor .mat-h2:not(:first-child),
.ql-snow .ql-editor .mat-headline-6:not(:first-child), .mat-h2:not(:first-child),
.mat-headline-6:not(:first-child) {
  padding-top: 12px;
}

h3, .ql-snow .ql-editor h3, .ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-subtitle-1, .mat-typography .ql-snow .ql-editor h3, .mat-typography .ql-snow .ql-editor .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1, .mat-h3,
.mat-subtitle-1,
.mat-typography h3 {
  font-size: 24px;
  line-height: 36px;
}
h3:not(:first-child), .ql-snow .ql-editor h3:not(:first-child), .ql-snow .ql-editor .mat-h3:not(:first-child),
.ql-snow .ql-editor .mat-subtitle-1:not(:first-child), .mat-h3:not(:first-child),
.mat-subtitle-1:not(:first-child) {
  padding-top: 10px;
}

h4, .ql-snow .ql-editor h4, .ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-body-2, .mat-typography .ql-snow .ql-editor h4, .mat-typography .ql-snow .ql-editor .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2, .mat-h4,
.mat-body-2,
.mat-typography h4 {
  font-size: 22px;
  line-height: 33px;
}
h4:not(:first-child), .ql-snow .ql-editor h4:not(:first-child), .ql-snow .ql-editor .mat-h4:not(:first-child),
.ql-snow .ql-editor .mat-body-2:not(:first-child), .mat-h4:not(:first-child),
.mat-body-2:not(:first-child) {
  padding-top: 8px;
}

h5, .ql-snow .ql-editor h5, .mat-typography .ql-snow .ql-editor h5 {
  font-size: 18px;
  line-height: 27px;
}

h6, .mat-typography .ql-snow .ql-editor h6 {
  font-size: 16px;
  line-height: 27px;
}

.footer {
  font-size: 1.2rem;
}

.oblique {
  font-style: italic;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.heavy {
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  h1, .ql-snow .ql-editor h1, .ql-snow .ql-editor .mat-h1,
  .ql-snow .ql-editor .mat-headline-5, .mat-typography .ql-snow .ql-editor h1, .mat-typography .ql-snow .ql-editor .mat-h1,
  .mat-typography .ql-snow .ql-editor .mat-headline-5, .mat-h1,
  .mat-headline-5,
  .mat-typography h1 {
    font-size: 28px;
    line-height: 38px;
  }
  h2, .ql-snow .ql-editor h2, .ql-snow .ql-editor .mat-h2,
  .ql-snow .ql-editor .mat-headline-6, .mat-typography .ql-snow .ql-editor h2, .mat-typography .ql-snow .ql-editor .mat-h2,
  .mat-typography .ql-snow .ql-editor .mat-headline-6, .mat-h2,
  .mat-headline-6,
  .mat-typography h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3, .ql-snow .ql-editor h3, .ql-snow .ql-editor .mat-h3,
  .ql-snow .ql-editor .mat-subtitle-1, .mat-typography .ql-snow .ql-editor h3, .mat-typography .ql-snow .ql-editor .mat-h3,
  .mat-typography .ql-snow .ql-editor .mat-subtitle-1, .mat-h3,
  .mat-subtitle-1,
  .mat-typography h3 {
    font-size: 18px;
    line-height: 27px;
  }
  h5, .ql-snow .ql-editor h5, .mat-typography .ql-snow .ql-editor h5 {
    font-size: 16px;
    line-height: 24px;
  }
  h6, .mat-typography .ql-snow .ql-editor h6 {
    font-size: 14px;
    line-height: 24px;
  }
}
p, .mat-body p,
.mat-subtitle-2 p,
.mat-typography p {
  line-height: 26px;
  margin-bottom: 16px;
}
p:last-child {
  padding-bottom: unset;
  margin-bottom: unset;
}

body {
  font-size: 16px;
  font-family: var(--font-family);
  background-color: var(--background);
  color: var(--gray-10);
  letter-spacing: 0.3px;
}

.hidden {
  visibility: hidden !important;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
a {
  font-weight: normal;
  text-decoration: none;
  color: var(--darkBlue);
}
a:hover {
  color: rgb(var(--warn-rgb), 0.7);
  cursor: pointer;
}
a[disabled] {
  pointer-events: none;
  color: rgb(var(--warn-rgb), 0.7);
  opacity: 0.38;
}

pre {
  padding: 12px;
  overflow: auto;
  border-radius: 3px;
  margin-bottom: 12px;
}

code {
  padding: 3px 8px;
  overflow: auto;
  border-radius: 3px;
}

@media (max-width: 768px) {
  pre {
    width: 93vw;
  }
}
label.text-quote {
  background-color: var(--gray-3);
  overflow: auto;
  padding: 1px 2px;
  color: rgb(var(--warn-rgb), 0.9);
  font-family: monospace;
}

.padding-0 {
  padding: 0px;
}

.padding-1 {
  padding: 3px;
}

.padding-2 {
  padding: 6px;
}

.padding-3 {
  padding: 9px;
}

.padding-4 {
  padding: 12px;
}

.padding-5 {
  padding: 15px;
}

.padding-6 {
  padding: 18px;
}

.padding-7 {
  padding: 24px;
}

.padding-8 {
  padding: 36px;
}

.padding-9 {
  padding: 48px;
}

.padding-10 {
  padding: 60px;
}

.padding-11 {
  padding: 72px;
}

.padding-12 {
  padding: 84px;
}

.padding-13 {
  padding: 96px;
}

.b-padding-0 {
  padding-bottom: 0px;
}

.b-padding-1 {
  padding-bottom: 3px;
}

.b-padding-2 {
  padding-bottom: 6px;
}

.b-padding-3, .quote .title {
  padding-bottom: 9px;
}

.b-padding-4 {
  padding-bottom: 12px;
}

.b-padding-5 {
  padding-bottom: 15px;
}

.b-padding-6, [bPageSpacing] {
  padding-bottom: 18px;
}

.b-padding-7 {
  padding-bottom: 24px;
}

.b-padding-8, .quote {
  padding-bottom: 36px;
}

.b-padding-9, [vPageSpacing] {
  padding-bottom: 48px;
}

.b-padding-10 {
  padding-bottom: 60px;
}

.b-padding-11 {
  padding-bottom: 72px;
}

.b-padding-12 {
  padding-bottom: 84px;
}

.b-padding-13 {
  padding-bottom: 96px;
}

.t-padding-0 {
  padding-top: 0px;
}

.t-padding-1 {
  padding-top: 3px;
}

.t-padding-2 {
  padding-top: 6px;
}

.t-padding-3 {
  padding-top: 9px;
}

.t-padding-4 {
  padding-top: 12px;
}

.t-padding-5 {
  padding-top: 15px;
}

.t-padding-6, [tPageSpacing] {
  padding-top: 18px;
}

.t-padding-7, [vPageSpacing], .quote {
  padding-top: 24px;
}

.t-padding-8 {
  padding-top: 36px;
}

.t-padding-9 {
  padding-top: 48px;
}

.t-padding-10 {
  padding-top: 60px;
}

.t-padding-11 {
  padding-top: 72px;
}

.t-padding-12 {
  padding-top: 84px;
}

.t-padding-13 {
  padding-top: 96px;
}

.h-padding-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.h-padding-1 {
  padding-left: 3px;
  padding-right: 3px;
}

.h-padding-2 {
  padding-left: 6px;
  padding-right: 6px;
}

.h-padding-3 {
  padding-left: 9px;
  padding-right: 9px;
}

.h-padding-4 {
  padding-left: 12px;
  padding-right: 12px;
}

.h-padding-5 {
  padding-left: 15px;
  padding-right: 15px;
}

.h-padding-6 {
  padding-left: 18px;
  padding-right: 18px;
}

.h-padding-7, [cardHrSpacing], [cardSpacing], [card][cardHrSpacing], [card][cardSpacing], .card-content-padding, .card-header {
  padding-left: 24px;
  padding-right: 24px;
}

.h-padding-8, .quote {
  padding-left: 36px;
  padding-right: 36px;
}

.h-padding-9 {
  padding-left: 48px;
  padding-right: 48px;
}

.h-padding-10, [cardExSpacing], [card][cardExSpacing] {
  padding-left: 60px;
  padding-right: 60px;
}

.h-padding-11 {
  padding-left: 72px;
  padding-right: 72px;
}

.h-padding-12 {
  padding-left: 84px;
  padding-right: 84px;
}

.h-padding-13 {
  padding-left: 96px;
  padding-right: 96px;
}

.v-padding-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.v-padding-1 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-padding-2 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.v-padding-3, .card-header {
  padding-top: 9px;
  padding-bottom: 9px;
}

.v-padding-4, .card-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}

.v-padding-5, [cardSpacing], [card][cardSpacing], .card-content-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-padding-6 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.v-padding-7, [cardVrSpacing] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.v-padding-8, [cardExSpacing], [card][cardExSpacing] {
  padding-top: 36px;
  padding-bottom: 36px;
}

.v-padding-9 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.v-padding-10 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.v-padding-11 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.v-padding-12 {
  padding-top: 84px;
  padding-bottom: 84px;
}

.v-padding-13 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.l-padding-0 {
  padding-left: 0px;
}

.l-padding-1 {
  padding-left: 3px;
}

.l-padding-2 {
  padding-left: 6px;
}

.l-padding-3, [hPageSpacing] {
  padding-left: 9px;
}

.l-padding-4 {
  padding-left: 12px;
}

.l-padding-5 {
  padding-left: 15px;
}

.l-padding-6 {
  padding-left: 18px;
}

.l-padding-7 {
  padding-left: 24px;
}

.l-padding-8 {
  padding-left: 36px;
}

.l-padding-9 {
  padding-left: 48px;
}

.l-padding-10 {
  padding-left: 60px;
}

.l-padding-11 {
  padding-left: 72px;
}

.l-padding-12 {
  padding-left: 84px;
}

.l-padding-13 {
  padding-left: 96px;
}

.r-padding-0 {
  padding-right: 0px;
}

.r-padding-1 {
  padding-right: 3px;
}

.r-padding-2 {
  padding-right: 6px;
}

.r-padding-3, [hPageSpacing] {
  padding-right: 9px;
}

.r-padding-4 {
  padding-right: 12px;
}

.r-padding-5 {
  padding-right: 15px;
}

.r-padding-6 {
  padding-right: 18px;
}

.r-padding-7 {
  padding-right: 24px;
}

.r-padding-8 {
  padding-right: 36px;
}

.r-padding-9 {
  padding-right: 48px;
}

.r-padding-10 {
  padding-right: 60px;
}

.r-padding-11 {
  padding-right: 72px;
}

.r-padding-12 {
  padding-right: 84px;
}

.r-padding-13 {
  padding-right: 96px;
}

[card] {
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}
[card][transparent] {
  background-color: transparent;
}
[cardShade] {
  box-shadow: 0 1px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}

[cardSmallSpacing] {
  padding: 6px 16px;
}

@media screen and (max-width: 480px) {
  [cardSpacing] {
    padding: 9px 12px;
  }
  [card][cardSpacing] {
    padding: 9px 12px;
  }
  [cardHrSpacing] {
    padding-left: 12px;
    padding-right: 12px;
  }
}
[blockquote] {
  border-left: 4px solid rgba(var(--primary-rgb), 0.6);
  padding: 8px 16px;
  font-style: italic;
  letter-spacing: 1px;
  background: var(--gray-7);
}

[border], label.text-quote {
  border: 1px solid #e9e9e9;
}

[errorBorder] {
  border: 1px solid var(--warn);
}

[warnBorder] {
  border: 1px solid var(--warning);
}

[warningBorder] {
  border: 1px solid rgb(230, 186, 127);
}

[topBorder] {
  border-top: 1px solid #e9e9e9;
}

[bottomBorder] {
  border-bottom: 1px solid #e9e9e9;
}

[leftBorder] {
  border-left: 1px solid #e9e9e9;
}

[rightBorder] {
  border-right: 1px solid #e9e9e9;
}

[career] {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: "career-logo career-detail career-button";
}
[career] [careerLogo] {
  grid-area: career-logo;
}
[career] [careerDetail] {
  grid-area: career-detail;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "career-detail-header" "career-detail-summary";
}
[career] [careerDetail] [careerDetailHeader] {
  grid-area: career-detail-header;
}
[career] [careerDetail] [careerDetailSummary] {
  padding-top: 18px;
  grid-area: career-detail-summary;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
[career] [careerButton] {
  grid-area: career-button;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "career-delete-button career-edit-button";
}
[career] [careerButton] [careerDeleteButton] {
  grid-area: "career-delete-button";
}
[career] [careerButton] [careerEditButton] {
  grid-area: "career-edit-button";
}

@media (max-width: 768px) {
  [career] {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "career-logo career-button" "career-detail career-detail";
  }
}
[centering][top] {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
[centering][topLeft] {
  display: inline-flex;
  align-items: flex-start;
  justify-content: left;
  text-align: left;
}
[centering][topRight] {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
}
[centering][center] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
[centering][centerLeft] {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  text-align: left;
}
[centering][centerRight] {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
[centering][bottom] {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}
[centering][bottomLeft] {
  display: inline-flex;
  align-items: flex-end;
  justify-content: left;
  text-align: left;
}
[centering][bottomRight] {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

[extraLight] {
  font-weight: 200;
}

[light] {
  font-weight: 300;
}

[normal] {
  font-weight: 400;
}

[semiBold], [instructor] [profile] [name] {
  font-weight: 600;
}

[bold] {
  font-weight: 700;
}

[extraBold] {
  font-weight: 800;
}

[image] {
  height: inherit;
  width: inherit;
  position: relative;
}
[image] img {
  height: 100%;
  width: 100%;
}

[imageOverlay] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(20, 16, 52, 0.69);
}

[imageOverlayLight] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.1);
}

[imageOverlayTransparent] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: transparent;
}

[image] [imageOverlay] {
  opacity: 1;
}

[image] [imageOverlayLight] {
  opacity: 1;
}

[image] [imageOverlayTransparent] {
  opacity: 1;
}

[mediumProfileImage] {
  height: 112px;
  width: 112px;
  position: relative;
}
[mediumProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15), 0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
}

[smallProfileImage] {
  height: 60px;
  width: 60px;
  position: relative;
}
[smallProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15), 0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
}

[extraSmallProfileImage] {
  height: 40px;
  width: 40px;
  position: relative;
}
[extraSmallProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

[microProfileImage] {
  height: 28px;
  width: 28px;
  position: relative;
}
[microProfileImage] img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

[largeLogo] {
  height: 120px;
  width: 120px;
  position: relative;
}
[largeLogo] img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #fff;
}

[logo] {
  height: 80px;
  width: 80px;
  position: relative;
}
[logo] img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #fff;
}

[smallLogo] {
  height: 60px;
  width: 60px;
  position: relative;
}
[smallLogo] img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

[extraSmallLogo] {
  height: 40px;
  width: 40px;
  position: relative;
}
[extraSmallLogo] img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

[imageFit], [instructor] [picture] img {
  object-fit: cover;
}

[avatarImages] {
  display: inline-block;
  transform: scale(-1, 1);
}
[avatarImages] [extraSmallAvatar] {
  position: relative;
  display: inline-block;
  background-color: var(--white);
  border: 2px solid var(--white);
  box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15), 0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
}
[avatarImages] [extraSmallAvatar] img {
  width: 100%;
  display: block;
  transform: scale(-1, 1);
}
[avatarImages] [extraSmallAvatar]:not(:first-child) {
  margin-left: -24px;
}

[paragraph] {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "title" "content";
}
[paragraph] [title] {
  grid-area: title;
}
[paragraph] [content] {
  grid-area: content;
}

[radius], label.text-quote {
  border-radius: 3px;
}

[radiusCircle] {
  border-radius: 50px;
}

[topRadius] {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

[bottomRadius] {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

[rightRadius] {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

[leftRadius] {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

[picoTitle] {
  font-size: 10px;
  line-height: 16px;
  font-family: var(--font-family-header);
}

[nanoTitle] {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-family-header);
}

[microTitle], [instructor] [profile] [position] {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-family-header);
}

[extraSmallTitle], [instructor] [profile] [institute] {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-family-header);
}

[smallTitle], [instructor] [profile] [name] {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--font-family-header);
}

[mediumTitle] {
  font-size: 24px;
  line-height: 36px;
  font-family: var(--font-family-header);
}

[largeTitle] {
  font-size: 32px;
  line-height: 48px;
  font-family: var(--font-family-header);
}

[extraLargeTitle] {
  font-size: 48px;
  line-height: 58px;
  font-family: var(--font-family-header);
}

[megaTitle] {
  font-size: 56px;
  line-height: 68px;
  font-family: var(--font-family-header);
}

[hexaTitle] {
  font-size: 60px;
  line-height: 72px;
  font-family: var(--font-family-header);
}

@media screen and (max-width: 480px) {
  [largeTitle] {
    font-size: 26px;
    line-height: 38px;
  }
  [mediumTitle] {
    font-size: 22px;
    line-height: 28px;
  }
  [smallTitle], [instructor] [profile] [name] {
    font-size: 16px;
    line-height: 24px;
  }
  [extraSmallTitle], [instructor] [profile] [institute] {
    font-size: 14px;
    line-height: 18px;
  }
}
[picoFont] {
  font-size: 10px;
}

[nanoFont] {
  font-size: 12px;
}

[microFont] {
  font-size: 14px;
}

[extraSmallFont] {
  font-size: 16px;
}

[smallFont] {
  font-size: 20px;
}

[mediumFont] {
  font-size: 24px;
}

[largeFont] {
  font-size: 32px;
}

[extraLargeFont] {
  font-size: 48px;
}

[megaFont] {
  font-size: 56px;
}

[hexaFont] {
  font-size: 60px;
}

[whiteFontColor] {
  color: var(--white);
}

[primaryFontColor] {
  color: var(--primary);
}

[secondaryFontColor] {
  color: var(--secondary);
}

[warningFontColor] {
  color: var(--warning);
}

[errorFontColor] {
  color: var(--error);
}

[g1FontColor] {
  color: var(--gray-98);
}

[g2FontColor] {
  color: var(--gray-40);
}

[g3FontColor] {
  color: var(--gray-30);
}

[g4FontColor] {
  color: var(--gray-20);
}

[g5FontColor] {
  color: var(--gray-15);
}

[g6FontColor] {
  color: var(--gray-10);
}

[gray1FontColor] {
  color: var(--gray-1);
}

[gray2FontColor] {
  color: var(--gray-2);
}

[gray3FontColor] {
  color: var(--gray-3);
}

[gray4FontColor] {
  color: var(--gray-4);
}

[gray5FontColor] {
  color: var(--gray-5);
}

[gray6FontColor] {
  color: var(--gray-6);
}

[gray7FontColor] {
  color: var(--gray-7);
}

[gray8FontColor] {
  color: var(--gray-8);
}

[gray9FontColor] {
  color: var(--gray-9);
}

[gray10FontColor] {
  color: var(--gray-10);
}

[gray11FontColor] {
  color: var(--gray-11);
}

[gray12FontColor] {
  color: var(--gray-12);
}

[overlay] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
}
[overlay][darkShade] {
  transition: 1.5s ease;
  background-color: rgba(20, 16, 52, 0.69);
}
[overlay][darkShade]:hover {
  background-color: rgba(20, 16, 52, 0.3);
}
[overlay][grayShade] {
  transition: 1.5s ease;
  background: -webkit-gradient(linear, right top, left top, color-stop(33%, rgba(244, 246, 248, 0.6)), color-stop(60%, #f4f6f8));
  background: linear-gradient(to left, rgba(244, 246, 248, 0.6) 33%, #f4f6f8 60%);
}

[overlayShade] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 1.5s ease;
  background-color: rgba(20, 16, 52, 0.69);
}

[overlayShade]:hover {
  background-color: rgba(20, 16, 52, 0.3);
}

[textShadow] {
  text-shadow: 0px 1px 2px var(--gray-10);
}

[overlayGrayShade] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 1.5s ease;
  background: -webkit-gradient(linear, right top, left top, color-stop(33%, rgba(244, 246, 248, 0.6)), color-stop(60%, #f4f6f8));
  background: linear-gradient(to left, rgba(244, 246, 248, 0.6) 33%, #f4f6f8 60%);
}

[pageSpacing] {
  padding: 32px;
}

[pageHrSpacing] {
  padding: 0px 32px;
}

[page] {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "top-nav" "body" "footer";
}
[page] [topNav] {
  grid-area: top-nav;
  z-index: 1000;
}
[page] [body] {
  grid-area: body;
}
[page] [footer] {
  grid-area: footer;
  z-index: 2;
}
[page] [topNav] + [body] {
  min-height: 85vh;
}

[extraLargePage] {
  max-width: 1440px;
}

[largePage] {
  max-width: 1180px;
}

[mediumPage] {
  max-width: 1140px;
}

[smallPage] {
  max-width: 1000px;
}

[extraSmallPage] {
  max-width: 950px;
}

[microPage] {
  max-width: 850px;
}

[nanoPage] {
  max-width: 750px;
}

.body-centering, [extraLargePage], [largePage], [mediumPage], [smallPage], [extraSmallPage], [microPage], [nanoPage] {
  margin: 0 auto !important;
  float: none !important;
}

@media (max-width: 959px) {
  [pageSpacing] {
    padding: 32px 12px;
  }
  [pageHrSpacing] {
    padding: 0px 12px;
  }
}
[pageBody] {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "page-title" "page-content";
}
[pageBody] [pageTitle] {
  grid-area: page-title;
}
[pageBody] [pageContent] {
  grid-area: page-content;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 250px;
  grid-template-rows: 1fr;
  grid-template-areas: "content ad-space";
}
[pageBody] [pageContent] [content] {
  grid-area: content;
}
[pageBody] [pageContent] [adSpace] {
  grid-area: ad-space;
}
[pageBody] [pageContentNoAd] {
  grid-area: page-content;
}

@media screen and (max-width: 959px) {
  [pageBody] [pageContent] {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "content" "ad-space";
  }
}
[section] {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "section-header section-content";
}
[section] [sectionHeader] {
  grid-area: section-header;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "section-title" "section-subtitle";
}
[section] [sectionHeader] [sectionTitle] {
  grid-area: section-title;
}
[section] [sectionHeader] [sectionSubitle] {
  grid-area: section-subtitle;
}
[section] [sectionContent] {
  grid-area: section-content;
}

@media screen and (max-width: 959px) {
  [section] {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "section-header" "section-content";
  }
}
[fullWidth] {
  width: 100%;
}

[halfWidth] {
  width: 50%;
}

[thirdWidth] {
  width: 75%;
}

[warningBackground] {
  background-color: var(--warningColor);
}

[bgLightYellow] {
  background-color: var(--lightYellow);
}

[bgWarning] {
  background-color: var(--warning);
}

[bgGray1] {
  background-color: var(--gray-1);
}

[bgGray2] {
  background-color: var(--gray-2);
}

[bgGray3] {
  background-color: var(--gray-3);
}

[bgGray98] {
  background-color: var(--gray-98);
}

[scrollbar][vertical] {
  overflow-y: auto;
  overflow-x: hidden;
}
[scrollbar][horizontal] {
  overflow-y: hidden;
  overflow-x: auto;
}
[scrollbar][bothAxis] {
  overflow-x: scroll;
  overflow-y: scroll;
}
[scrollbar][grayScrollbar]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}
[scrollbar][grayScrollbar]::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #F5F5F5;
}
[scrollbar][grayScrollbar]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f9f9f9 -5%, #9da3a6 100%);
  background-image: linear-gradient(120deg, #f9f9f9 -5%, #9da3a6 100%);
}
[scrollbar][primaryScrollbar]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}
[scrollbar][primaryScrollbar]::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #F5F5F5;
}
[scrollbar][primaryScrollbar]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f9f9f9 -5%, var(--primary) 100%);
  background-image: linear-gradient(120deg, #f9f9f9 -5%, var(--primary) 100%);
}
[scrollbar][secondaryScrollbar]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}
[scrollbar][secondaryScrollbar]::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: #F5F5F5;
}
[scrollbar][secondaryScrollbar]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f9f9f9 -5%, var(--secondary) 100%);
  background-image: linear-gradient(120deg, #f9f9f9 -5%, var(--secondary) 100%);
}

[representativeRole] {
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px 12px;
  background: #f0f0f0;
  background: var(--gray-3);
  color: var(--gray-9);
  border: 1px solid #ececec;
  border: 1px solid var(--gray-4);
}

[instructor] [profile] {
  grid-gap: 6px;
}
[revisionStatus] {
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px 12px;
  background: var(--gray-3);
  border: 1px solid var(--gray-4);
}
[revisionStatus][live] {
  color: var(--material-palette-secondary-700);
}
[revisionStatus][draft] {
  color: var(--warning);
}
[revisionStatus][retired] {
  color: var(--gray-9);
}

[chip] {
  font-weight: 600;
  font-size: 13px;
  background-color: var(--gray-6);
  border: 1px solid var(--gray-7);
  border-radius: 14.5px;
  text-align: center;
  padding: 3px 12px;
}
[chip][light] {
  background-color: var(--gray-3);
  border: 1px solid var(--gray-4);
}
[chip][outline] {
  color: var(--gray-9);
  background-color: var(--white);
  border: 1px solid var(--gray-4);
}
[chip][live] {
  color: var(--material-palette-secondary-700);
}
[chip][draft] {
  color: var(--warning);
}
[chip][retired] {
  color: var(--gray-9);
}
[chip][rejected] {
  color: var(--warn);
}

[dot] {
  margin: 2px 4px;
  height: 4px;
  width: 4px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

[stickySection] {
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
}

[stickyHeader], [page] [topNav] {
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  align-self: flex-end;
}

[stickyFooter] {
  position: sticky;
  -webkit-position: sticky;
  bottom: 0px;
  align-self: flex-end;
}

.fusion-calendar-event__default span {
  color: var(--primary) !important;
}

.fusion-calendar-event__rejected span {
  text-decoration: line-through !important;
  color: var(--warn) !important;
}

.event__expired span {
  opacity: 50%;
}

.cal-month-view .cal-open-day-events {
  background-color: var(--secondary) !important;
  box-shadow: unset !important;
}

/* Extend default snow styles*/
.ql-editor:focus {
  outline: none;
  border-bottom: 2px solid var(--primary);
}

.ql-container {
  box-sizing: border-box;
  font-size: 1rem;
  height: 100%;
  margin: 0px;
  position: relative;
  font-family: var(--font-family);
}

.ql-editor {
  border: none;
  /* border: 1px solid #949494; */
}

.ql-snow .ql-editor a {
  text-decoration: none;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: initial;
  background-color: var(--gray-5);
}

.ql-snow .ql-editor pre {
  margin-bottom: 12px;
}

.ql-snow .ql-editor code {
  font-size: inherit;
  background-color: var(--gray-5) !important;
}

.ql-editor ol,
.ql-editor ul {
  margin-block-end: 1em;
}

.ql-editor ol,
.ql-editor ul {
  line-height: 26px;
  margin: 0 0 12px;
}
.ql-editor ol:last-child,
.ql-editor ul:last-child {
  padding-bottom: unset;
}
.ql-editor ol li,
.ql-editor ul li {
  margin-bottom: 8px;
}
.ql-editor ol li:last-child,
.ql-editor ul li:last-child {
  margin-bottom: unset;
}

.ql-snow .ql-editor blockquote {
  line-height: 24px;
  letter-spacing: 1px;
  border-left: 4px solid var(--blue);
  margin: 12px 0px;
  padding: 12px 16px;
  border-radius: 3px;
  background: rgba(var(--blueRgb), 0.2);
}

/* typography */
.ql-snow .ql-editor h1, .ql-snow .ql-editor .mat-h1,
.ql-snow .ql-editor .mat-headline-5, .mat-typography .ql-snow .ql-editor .mat-h1,
.mat-typography .ql-snow .ql-editor .mat-headline-5,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor .mat-h2,
.ql-snow .ql-editor .mat-headline-6,
.mat-typography .ql-snow .ql-editor .mat-h2,
.mat-typography .ql-snow .ql-editor .mat-headline-6,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor .mat-h3,
.ql-snow .ql-editor .mat-subtitle-1,
.mat-typography .ql-snow .ql-editor .mat-h3,
.mat-typography .ql-snow .ql-editor .mat-subtitle-1,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor .mat-h4,
.ql-snow .ql-editor .mat-body-2,
.mat-typography .ql-snow .ql-editor .mat-h4,
.mat-typography .ql-snow .ql-editor .mat-body-2,
.ql-snow .ql-editor h5,
.mat-typography .ql-snow .ql-editor h5 {
  font-weight: 600;
  padding-bottom: 6px;
  font-family: var(--font-family-header);
}

.ql-snow .ql-picker.ql-header {
  width: 125px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Section Title 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Section Title 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Section Title 3";
}

/* divider styles */
.ql-divider {
  font-size: 20px;
  font-weight: 600;
  padding: unset !important;
}

.ql-divider:before {
  content: "—";
}

/* toolbar position */
.ql-container.ql-snow {
  height: auto;
}

.ql-editor {
  height: 200px;
  overflow-y: scroll;
}

ul,
ol {
  line-height: 26px;
  margin: 0 0 12px;
}
ul:last-child,
ol:last-child {
  padding-bottom: unset;
}
ul li,
ol li {
  margin-bottom: 8px;
}
ul li:last-child,
ol li:last-child {
  margin-bottom: unset;
}

[transition] {
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}