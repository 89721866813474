@import '../core/variables';

// font sizes
[picoFont] {
  font-size: $picoFont;
}

[nanoFont] {
  font-size: $nanoFont;
}

[microFont] {
  font-size: $microFont;
}

[extraSmallFont] {
  font-size: $extraSmallFont;
}

[smallFont] {
  font-size: $smallFont;
}

[mediumFont] {
  font-size: $mediumFont;
}

[largeFont] {
  font-size: $largeFont;
}

[extraLargeFont] {
  font-size: $extraLargeFont;
}

[megaFont] {
  font-size: $megaFont;
}

[hexaFont] {
  font-size: $hexaFont;
}