@import '../core/variables';

// title
[picoTitle] {
  font-size: $picoFont;
  line-height: $picoLineHeight;
  font-family: var(--font-family-header);
}

[nanoTitle] {
  font-size  : $nanoFont;
  line-height: $nanoLineHeight;
  font-family: var(--font-family-header);
}

[microTitle] {
  font-size  : $microFont;
  line-height: $microLineHeight;
  font-family: var(--font-family-header);
}

[extraSmallTitle] {
  font-size  : $extraSmallFont;
  line-height: $extraSmallLineHeight;
  font-family: var(--font-family-header);
}

[smallTitle] {
  font-size  : $smallFont;
  line-height: $smallLineHeight;
  font-family: var(--font-family-header);
}

[mediumTitle] {
  font-size  : $mediumFont;
  line-height: $mediumLineHeight;
  font-family: var(--font-family-header);
}

[largeTitle] {
  font-size  : $largeFont;
  line-height: $largeLineHeight;
  font-family: var(--font-family-header);
}

[extraLargeTitle] {
  font-size  : $extraLargeFont;
  line-height: $extraLargeLineHeight;
  font-family: var(--font-family-header);
}

[megaTitle] {
  font-size  : $megaFont;
  line-height: $megaLineHeight;
  font-family: var(--font-family-header);
}

[hexaTitle] {
  font-size  : $hexaFont;
  line-height: $hexaLineHeight;
  font-family: var(--font-family-header);
}

// responsive view
@media screen and (max-width: $microBreak) {
  [largeTitle] {
    font-size: 26px;
    line-height: 38px;
  }
  [mediumTitle] {
    font-size: 22px;
    line-height: 28px;
  }
  [smallTitle] {
    font-size  : 16px;
    line-height: 24px;
  }
  [extraSmallTitle] {
    font-size  : 14px;
    line-height: 18px;
  }
}
