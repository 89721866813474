// font weight
[extraLight] {
  font-weight: 200;
}

[light] {
  font-weight: 300;
}

[normal] {
  font-weight: 400;
}

[semiBold] {
  font-weight: 600;
}

[bold] {
  font-weight: 700;
}

[extraBold] {
  font-weight: 800;
}