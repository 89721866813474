@import '../core/variables';

// paragraph
[paragraph] {
  display              : grid;
  grid-gap             : $smallGap;
  grid-template-columns: 1fr;
  grid-template-rows   : auto 1fr;
  grid-template-areas  :
    'title'
    'content';

  [title] {
    grid-area: title;
  }

  [content] {
    grid-area: content;
  }
}