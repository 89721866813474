@import './variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family   : var(--font-family-header);
  font-weight   : 600;
  padding-bottom: 6px;
}

h1 {
  font-size  : 36px;
  line-height: 54px;
  color      : var(--primary);

  &:not(:first-child) {
    padding-top: 12px;
  }
}

h2 {
  font-size  : 28px;
  line-height: 42px;

  &:not(:first-child) {
    padding-top: 12px;
  }
}

h3 {
  font-size  : 24px;
  line-height: 36px;

  &:not(:first-child) {
    padding-top: 10px;
  }
}

h4 {
  font-size  : 22px;
  line-height: 33px;

  &:not(:first-child) {
    padding-top: 8px;
  }
}

h5 {
  font-size  : 18px;
  line-height: 27px;
}

h6 {
  font-size  : 16px;
  line-height: 27px;
}

.footer {
  font-size: 1.2rem;
}

.oblique {
  font-style: italic;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.heavy {
  font-weight: 800;
}

// responsive view
@media screen and (max-width: $extraSmallBreak) {
  h1 {
    font-size  : 28px;
    line-height: 38px;
  }

  h2 {
    font-size  : 24px;
    line-height: 32px;
  }

  h3 {
    font-size  : 18px;
    line-height: 27px;
  }

  h5 {
    font-size  : 16px;
    line-height: 24px;
  }

  h6 {
    font-size  : 14px;
    line-height: 24px;
  }
}

// paragraph
p {
  line-height  : 26px;
  margin-bottom: 16px;

  &:last-child {
    padding-bottom: unset;
    margin-bottom : unset;
  }
}

// material typography fix for non material contents/components
.mat-h1,
.mat-headline-5,
.mat-typography h1 {
  @extend h1;
}

.mat-h2,
.mat-headline-6,
.mat-typography h2 {
  @extend h2;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography h3 {
  @extend h3;
}

.mat-h4,
.mat-body-2,
.mat-typography h4 {
  @extend h4;
}

.mat-body p,
.mat-subtitle-2 p,
.mat-typography p {
  @extend p;
}

// material typography fix for quill editor
.mat-typography .ql-snow .ql-editor h1 {
  @extend h1;
}

.mat-typography .ql-snow .ql-editor h2 {
  @extend h2;
}

.mat-typography .ql-snow .ql-editor h3 {
  @extend h3;
}

.mat-typography .ql-snow .ql-editor h4 {
  @extend h4;
}

.mat-typography .ql-snow .ql-editor h5 {
  @extend h5;
}

.mat-typography .ql-snow .ql-editor h6 {
  @extend h6;
}