// responsive view
@media screen and (max-width: 480px) {
  .mat-stepper-vertical .mat-vertical-stepper-header {
    padding: 12px 6px !important;
  }

  .mat-vertical-content {
    padding: 12px !important;
  }

  .mat-stepper-vertical .mat-vertical-content-container {
    margin-left: 18px !important;
  }
}