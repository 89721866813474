@import './variables';

// paddings
.padding-0 {
  padding: $spacing-0;
}

.padding-1 {
  padding: $spacing-1;
}

.padding-2 {
  padding: $spacing-2;
}

.padding-3 {
  padding: $spacing-3;
}

.padding-4 {
  padding: $spacing-4;
}

.padding-5 {
  padding: $spacing-5;
}

.padding-6 {
  padding: $spacing-6;
}

.padding-7 {
  padding: $spacing-7;
}

.padding-8 {
  padding: $spacing-8;
}

.padding-9 {
  padding: $spacing-9;
}

.padding-10 {
  padding: $spacing-10;
}

.padding-11 {
  padding: $spacing-11;
}

.padding-12 {
  padding: $spacing-12;
}

.padding-13 {
  padding: $spacing-13;
}

// b-paddings
.b-padding-0 {
  padding-bottom: $spacing-0;
}

.b-padding-1 {
  padding-bottom: $spacing-1;
}

.b-padding-2 {
  padding-bottom: $spacing-2;
}

.b-padding-3 {
  padding-bottom: $spacing-3;
}

.b-padding-4 {
  padding-bottom: $spacing-4;
}

.b-padding-5 {
  padding-bottom: $spacing-5;
}

.b-padding-6 {
  padding-bottom: $spacing-6;
}

.b-padding-7 {
  padding-bottom: $spacing-7;
}

.b-padding-8 {
  padding-bottom: $spacing-8;
}

.b-padding-9 {
  padding-bottom: $spacing-9;
}

.b-padding-10 {
  padding-bottom: $spacing-10;
}

.b-padding-11 {
  padding-bottom: $spacing-11;
}

.b-padding-12 {
  padding-bottom: $spacing-12;
}

.b-padding-13 {
  padding-bottom: $spacing-13;
}

// t-paddings
.t-padding-0 {
  padding-top: $spacing-0;
}

.t-padding-1 {
  padding-top: $spacing-1;
}

.t-padding-2 {
  padding-top: $spacing-2;
}

.t-padding-3 {
  padding-top: $spacing-3;
}

.t-padding-4 {
  padding-top: $spacing-4;
}

.t-padding-5 {
  padding-top: $spacing-5;
}

.t-padding-6 {
  padding-top: $spacing-6;
}

.t-padding-7 {
  padding-top: $spacing-7;
}

.t-padding-8 {
  padding-top: $spacing-8;
}

.t-padding-9 {
  padding-top: $spacing-9;
}

.t-padding-10 {
  padding-top: $spacing-10;
}

.t-padding-11 {
  padding-top: $spacing-11;
}

.t-padding-12 {
  padding-top: $spacing-12;
}

.t-padding-13 {
  padding-top: $spacing-13;
}

// h-padding
.h-padding-0 {
  padding-left : $spacing-0;
  padding-right: $spacing-0;
}

.h-padding-1 {
  padding-left : $spacing-1;
  padding-right: $spacing-1;
}

.h-padding-2 {
  padding-left : $spacing-2;
  padding-right: $spacing-2;
}

.h-padding-3 {
  padding-left : $spacing-3;
  padding-right: $spacing-3;
}

.h-padding-4 {
  padding-left : $spacing-4;
  padding-right: $spacing-4;
}

.h-padding-5 {
  padding-left : $spacing-5;
  padding-right: $spacing-5;
}

.h-padding-6 {
  padding-left : $spacing-6;
  padding-right: $spacing-6;
}

.h-padding-7 {
  padding-left : $spacing-7;
  padding-right: $spacing-7;
}

.h-padding-8 {
  padding-left : $spacing-8;
  padding-right: $spacing-8;
}

.h-padding-9 {
  padding-left : $spacing-9;
  padding-right: $spacing-9;
}

.h-padding-10 {
  padding-left : $spacing-10;
  padding-right: $spacing-10;
}

.h-padding-11 {
  padding-left : $spacing-11;
  padding-right: $spacing-11;
}

.h-padding-12 {
  padding-left : $spacing-12;
  padding-right: $spacing-12;
}

.h-padding-13 {
  padding-left : $spacing-13;
  padding-right: $spacing-13;
}

// v-padding
.v-padding-0 {
  padding-top   : $spacing-0;
  padding-bottom: $spacing-0;
}

.v-padding-1 {
  padding-top   : $spacing-1;
  padding-bottom: $spacing-1;
}

.v-padding-2 {
  padding-top   : $spacing-2;
  padding-bottom: $spacing-2;
}

.v-padding-3 {
  padding-top   : $spacing-3;
  padding-bottom: $spacing-3;
}

.v-padding-4 {
  padding-top   : $spacing-4;
  padding-bottom: $spacing-4;
}

.v-padding-5 {
  padding-top   : $spacing-5;
  padding-bottom: $spacing-5;
}

.v-padding-6 {
  padding-top   : $spacing-6;
  padding-bottom: $spacing-6;
}

.v-padding-7 {
  padding-top   : $spacing-7;
  padding-bottom: $spacing-7;
}

.v-padding-8 {
  padding-top   : $spacing-8;
  padding-bottom: $spacing-8;
}

.v-padding-9 {
  padding-top   : $spacing-9;
  padding-bottom: $spacing-9;
}

.v-padding-10 {
  padding-top   : $spacing-10;
  padding-bottom: $spacing-10;
}

.v-padding-11 {
  padding-top   : $spacing-11;
  padding-bottom: $spacing-11;
}

.v-padding-12 {
  padding-top   : $spacing-12;
  padding-bottom: $spacing-12;
}

.v-padding-13 {
  padding-top   : $spacing-13;
  padding-bottom: $spacing-13;
}

// l-paddings
.l-padding-0 {
  padding-left: $spacing-0;
}

.l-padding-1 {
  padding-left: $spacing-1;
}

.l-padding-2 {
  padding-left: $spacing-2;
}

.l-padding-3 {
  padding-left: $spacing-3;
}

.l-padding-4 {
  padding-left: $spacing-4;
}

.l-padding-5 {
  padding-left: $spacing-5;
}

.l-padding-6 {
  padding-left: $spacing-6;
}

.l-padding-7 {
  padding-left: $spacing-7;
}

.l-padding-8 {
  padding-left: $spacing-8;
}

.l-padding-9 {
  padding-left: $spacing-9;
}

.l-padding-10 {
  padding-left: $spacing-10;
}

.l-padding-11 {
  padding-left: $spacing-11;
}

.l-padding-12 {
  padding-left: $spacing-12;
}

.l-padding-13 {
  padding-left: $spacing-13;
}

// r-paddings
.r-padding-0 {
  padding-right: $spacing-0;
}

.r-padding-1 {
  padding-right: $spacing-1;
}

.r-padding-2 {
  padding-right: $spacing-2;
}

.r-padding-3 {
  padding-right: $spacing-3;
}

.r-padding-4 {
  padding-right: $spacing-4;
}

.r-padding-5 {
  padding-right: $spacing-5;
}

.r-padding-6 {
  padding-right: $spacing-6;
}

.r-padding-7 {
  padding-right: $spacing-7;
}

.r-padding-8 {
  padding-right: $spacing-8;
}

.r-padding-9 {
  padding-right: $spacing-9;
}

.r-padding-10 {
  padding-right: $spacing-10;
}

.r-padding-11 {
  padding-right: $spacing-11;
}

.r-padding-12 {
  padding-right: $spacing-12;
}

.r-padding-13 {
  padding-right: $spacing-13;
}