
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile.mat-button-toggle-group-appearance-standard {
  border-radius: 0px 0px 0px 4px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile.mat-button-toggle-group-appearance-standard {
  border-right: solid 1px rgba(0, 0, 0, 0.12) !important;
  border-left: solid 0px rgba(0, 0, 0, 0.12) !important;
  border-top: solid 0px rgba(0, 0, 0, 0.12) !important;
  border-bottom: solid 0px rgba(0, 0, 0, 0.12) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.jobseeker-profile .mat-button-toggle-label-content {
  line-height: 58px !important;
  padding: 0 28px !important;
}

// employer
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.profile-nav.mat-button-toggle-group-appearance-standard {
  border-radius: 4px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.profile-nav .mat-button-toggle-label-content {
  line-height: 58px !important;
  padding: 0 30px !important;
  text-align: left;
}
