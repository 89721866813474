.fusion-calendar-event__default span {
  color: var(--primary) !important;
}

.fusion-calendar-event__rejected span {
  text-decoration: line-through !important;
  color: var(--warn) !important;
}

.event__expired span {
  opacity: 50%;
}

.cal-month-view .cal-open-day-events {
  background-color: var(--secondary) !important;
  box-shadow: unset !important;
}