@import '../core/variables';

[scrollbar] {
  &[vertical] {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &[horizontal] {
    overflow-y: hidden;
    overflow-x: auto;
  }

  &[bothAxis] {
    overflow-x: scroll;
    overflow-y: scroll;
  }

  &[grayScrollbar]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color  : #F5F5F5;
    border-radius     : 10px;
  }

  &[grayScrollbar]::-webkit-scrollbar {
    height          : 6px;
    width           : 6px;
    background-color: #F5F5F5;
  }

  &[grayScrollbar]::-webkit-scrollbar-thumb {
    border-radius     : 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image  : -webkit-linear-gradient(330deg, #f9f9f9 -5%, #9da3a6 100%);
    background-image  : linear-gradient(120deg, #f9f9f9 -5%, #9da3a6 100%);
  }

  &[primaryScrollbar]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color  : #F5F5F5;
    border-radius     : 10px;
  }

  &[primaryScrollbar]::-webkit-scrollbar {
    height          : 6px;
    width           : 6px;
    background-color: #F5F5F5;
  }

  &[primaryScrollbar]::-webkit-scrollbar-thumb {
    border-radius     : 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image  : -webkit-linear-gradient(330deg, #f9f9f9 -5%, var(--primary) 100%);
    background-image  : linear-gradient(120deg, #f9f9f9 -5%, var(--primary) 100%);
  }

  &[secondaryScrollbar]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color  : #F5F5F5;
    border-radius     : 10px;
  }

  &[secondaryScrollbar]::-webkit-scrollbar {
    height          : 6px;
    width           : 6px;
    background-color: #F5F5F5;
  }

  &[secondaryScrollbar]::-webkit-scrollbar-thumb {
    border-radius     : 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image  : -webkit-linear-gradient(330deg, #f9f9f9 -5%, var(--secondary) 100%);
    background-image  : linear-gradient(120deg, #f9f9f9 -5%, var(--secondary) 100%);
  }
}