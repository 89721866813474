@import '../core/variables';

// background
[warningBackground] {
  background-color: var(--warningColor);
}

[bgLightYellow] {
  background-color: var(--lightYellow);
}

[bgWarning] {
  background-color: var(--warning);
}

[bgGray1] {
  background-color: var(--gray-1);
}

[bgGray2] {
  background-color: var(--gray-2);
}

[bgGray3] {
  background-color: var(--gray-3);
}
[bgGray98] {
  background-color: var(--gray-98);
}
