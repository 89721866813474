@import '../core/variables';
@import '../core/padding';

[card] {
  border-radius   : $radius;
  background-color: var(--white);
  box-shadow      : 0 1px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);

  // &:hover {
  //   box-shadow: 0 5px 5px rgba(10, 16, 20, 0.24), 0 0 5px rgba(10, 16, 20, 0.12);
  //   transition: box-shadow 0.3s;
  // }
  &[transparent] {
    background-color: transparent;
  }

  &[cardSpacing] {
    @extend .v-padding-5;
    @extend .h-padding-7;
  }

  &[cardHrSpacing] {
    @extend .h-padding-7;
  }

  &[cardExSpacing] {
    @extend .v-padding-8;
    @extend .h-padding-10;
  }
}

[cardShade] {
  box-shadow: 0 1px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}

[cardSpacing] {
  @extend .v-padding-5;
  @extend .h-padding-7;
}

[cardHrSpacing] {
  @extend .h-padding-7;
}

[cardVrSpacing] {
  @extend .v-padding-7;
}

[cardExSpacing] {
  @extend .v-padding-8;
  @extend .h-padding-10;
}

[cardSmallSpacing] {
  padding: 6px 16px;
}

// responsive view
@media screen and (max-width: $microBreak) {

  [cardSpacing] {
    padding: 9px 12px;
  }

  [card] {
    &[cardSpacing] {
      padding: 9px 12px;
    }
  }

  [cardHrSpacing] {
    padding-left : 12px;
    padding-right: 12px;
  }
}