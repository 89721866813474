@use '@angular/material' as mat;
@import '../core/variables';

// sticky
[sticky] {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top     : 0px;
  z-index : 2;
}

[fixedPosition] {
  position: fixed;
  width: 100%;
  top     : 0px;
  z-index : 2;
}

// elevation
[elevationActive] {
  @include mat.elevation(10);
}

[elevation]:hover {
  @include mat.elevation(5);
}

// overflow
[dotOverflow] {
  display      : inline-block;
  width        : inherit;
  white-space  : nowrap;
  overflow     : hidden !important;
  text-overflow: ellipsis;
}

// image
[image] {
  height  : inherit;
  width   : inherit;
  position: relative;

  img {
    height: 100%;
    width : 100%;
  }
}

[profileImage] {
  height  : 112px;
  width   : 112px;
  position: relative;

  img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
    border       : 4px solid #fff;
    box-shadow   : inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15);
  }
}

[smallProfileImage] {
  height  : 60px;
  width   : 60px;
  position: relative;

  img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
  }
}

[exSmallProfileImage] {
  height  : 35px;
  width   : 35px;
  position: relative;

  img {
    width        : 100%;
    height       : 100%;
    border-radius: 50%;
  }
}

[logo] {
  height  : 80px;
  width   : 80px;
  position: relative;

  img {
    margin-top: 5px;
    width     : 100%;
    height    : 100%;
    border    : 1px solid #fff;
  }
}

[smallLogo] {
  height  : 60px;
  width   : 60px;
  position: relative;

  img {
    margin-top: 2px;
    width     : 100%;
    height    : 100%;
  }
}

[imageFit] {
  object-fit: cover;
}

// spacing
// [pageSpacing] {
//   @extend .h-padding-3;
//   @extend .v-padding-6;
// }

[tPageSpacing] {
  @extend .t-padding-6;
}

[bPageSpacing] {
  @extend .b-padding-6;
}

[hPageSpacing] {
  @extend .l-padding-3;
  @extend .r-padding-3;
}

[vPageSpacing] {
  @extend .t-padding-7;
  @extend .b-padding-9;
}

// hover
[hover] {
  [hoverDisplay] {
    visibility: hidden;
  }

  [primaryBgColorHover],
  [secondaryBgColorHover],
  [bgColorHover],
  [g1BgColorHover],
  [g2BgColorHover],
  [g3BgColorHover],
  [g4BgColorHover],
  [g5BgColorHover],
  [g6BgColorHover] {
    background-color: none;
  }

  &:hover {
    [hoverDisplay] {
      visibility: visible;
    }

    [primaryBgColorHover] {
      background-color: var(--primary);
    }

    [secondaryBgColorHover] {
      background-color: var(--secondary);
    }

    [bgColorHover] {
      background-color: rgba(0, 0, 0, 0.03);
    }

    [g1BgColorHover] {
      background-color: var(--gray-98);
    }

    [g2BgColorHover] {
      background-color: var(--gray-40);
    }

    [g3BgColorHover] {
      background-color: var(--gray-30);
    }

    [g4BgColorHover] {
      background-color: var(--gray-20);
    }

    [g5BgColorHover] {
      background-color: var(--gray-15);
    }

    [g6BgColorHover] {
      background-color: var(--gray-10);
    }
  }
}

// pointer
[pointer]:hover {
  cursor: pointer;
}

[noPointer]:hover {
  cursor: no-drop !important;
}

// transform
[uppercase] {
  text-transform: uppercase;
}

[capitalize] {
  text-transform: capitalize;
}

// background color
[primaryBgColor] {
  background-color: var(--primary);
}

[secondaryBgColor] {
  background-color: var(--secondary);
}

[whiteBgColor] {
  background-color: var(--white);
}

[bgColor] {
  background-color: rgba(0, 0, 0, 0.03);
}

[g1BgColor] {
  background-color: var(--gray-98);
}

[g2BgColor] {
  background-color: var(--gray-40);
}

[g3BgColor] {
  background-color: var(--gray-30);
}

[g4BgColor] {
  background-color: var(--gray-20);
}

[g5BgColor] {
  background-color: var(--gray-15);
}

[g6BgColor] {
  background-color: var(--gray-10);
}

[successBgColor] {
  background-color: var(--success-bg);
}

[warningBgColor] {
  background-color: #fffed9;
}

[errorBgColor] {
  background-color: var(--error-bg);
}

// font color
[whiteFontColor] {
  color: var(--white);
}

[primaryFontColor] {
  color: var(--primary);
}

[secondaryFontColor] {
  color: var(--secondary);
}

[g1FontColor] {
  color: var(--gray-98);
}

[g2FontColor] {
  color: var(--gray-40);
}

[g3FontColor] {
  color: var(--gray-30);
}

[g4FontColor] {
  color: var(--gray-20);
}

[g5FontColor] {
  color: var(--gray-15);
}

[g6FontColor] {
  color: var(--gray-10);
}

// font positioning
[centering] {
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  vertical-align : middle;
  text-align     : center;
}

[centeringLeft] {
  display        : inline-flex;
  align-items    : center;
  justify-content: left;
  vertical-align : middle;
}

[centeringRight] {
  display        : inline-flex;
  align-items    : center;
  justify-content: flex-end;
  vertical-align : middle;
}

[centeringTop] {
  display        : inline-flex;
  align-items    : flex-start;
  justify-content: center;
  vertical-align : middle;
  text-align     : center;
}

[centeringBottom] {
  display        : inline-flex;
  align-items    : flex-end;
  justify-content: center;
  vertical-align : middle;
  text-align     : center;
}

[centeringImage] {
  margin         : auto;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  vertical-align : middle;
  text-align     : center;
}

[centeringText] {
  text-align: center;
}

[alignLeft] {
  text-align: left;
}

[alignRight] {
  text-align: right;
}

// font weight
[exLight] {
  font-weight: 200;
}

[light] {
  font-weight: 300;
}

[normal] {
  font-weight: 400;
}

[microBold] {
  font-weight: 500;
}

[semiBold] {
  font-weight: 600;
}

[bold] {
  font-weight: 700;
}

[extraBold] {
  font-weight: 800;
}

// font size
[microFont] {
  font-size: 10px;
}

[exSmallFont] {
  font-size: 12px;
}

[smallFont] {
  font-size: 15px;
}

[exMediumFont] {
  font-size: 18px;
}

[mediumFont] {
  font-size: 22px;
}

[largeFont] {
  font-size: 28px;
}

[exLargeFont] {
  font-size: 36px;
}

[exxLargeFont] {
  font-size: 48px;
}

// title
[microTitle] {
  font-size  : 12px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

[exxSmallTitle] {
  font-size  : 14px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

[exSmallTitle] {
  font-size  : 16px;
  line-height: 22px;
  font-family: var(--font-family-header);
}

// [smallTitle] {
//   font-size  : 18px;
//   line-height: 27px;
//   font-family: var(--font-family-header);
// }

[exMediumTitle] {
  font-size  : 22px;
  line-height: 27px;
  font-family: var(--font-family-header);
}

[mediumTitle] {
  font-size  : 24px;
  line-height: 36px;
  font-family: var(--font-family-header);
}

[largeTitle] {
  font-size  : 28px;
  line-height: 42px;
  font-family: var(--font-family-header);
}

[exLargeTitle] {
  font-size  : 36px;
  line-height: 54px;
  font-family: var(--font-family-header);
}

[exxLargeTitle] {
  font-size  : 46px;
  line-height: 54px;
  font-family: var(--font-family-header);
}

[exxxLargeTitle] {
  font-size  : 56px;
  line-height: 72px;
  font-family: var(--font-family-header);
}

// border
[border] {
  border: 1px solid #e9e9e9;
}

[topBorder] {
  border-top: 1px solid #e9e9e9;
}

[bottomBorder] {
  border-bottom: 1px solid #e9e9e9;
}

[leftBorder] {
  border-left: 1px solid #e9e9e9;
}

[rightBorder] {
  border-right: 1px solid #e9e9e9;
}

// divider
[topDivider] {
  width        : 100%;
  margin-top   : 6px;
  margin-bottom: 6px;
  border-top   : 1px solid #e9e9e9;

  &:first-child {
    margin-top: 0px;
  }
}

[bottomDivider] {
  width        : 100%;
  margin-top   : 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #e9e9e9;

  &:last-child {
    margin-bottom: 0px;
  }
}

[leftDivider] {
  height     : 100%;
  border-left: 1px solid #e9e9e9;

  &:first-child {
    margin-left: 0px;
  }
}

[rightDivider] {
  height      : 100%;
  border-right: 1px solid #e9e9e9;

  &:first-child {
    margin-right: 0px;
  }
}

// radius
[radius] {
  border-radius: $radius;
}

[tRadius] {
  border-top-left-radius : $radius;
  border-top-right-radius: $radius;
}

[bRadius] {
  border-bottom-left-radius : $radius;
  border-bottom-right-radius: $radius;
}

[rRadius] {
  border-top-right-radius   : $radius;
  border-bottom-right-radius: $radius;
}

[lRadius] {
  border-top-left-radius   : $radius;
  border-bottom-left-radius: $radius;
}