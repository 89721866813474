.mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.ignore) {
  background-color: transparent;
}

.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
  width: 28px;
  height: 28px;
  padding: 2px;
}

@media screen and (max-width: 480px) {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    width: unset !important;
  }
}