// hyperlinks
a {
  font-weight    : normal;
  text-decoration: none;
  color          : var(--darkBlue);

  &:hover {
    color : rgb(var(--warn-rgb), .7);
    cursor: pointer;
  }

  &[disabled] {
    pointer-events: none;
    color         : rgb(var(--warn-rgb), .7);
    opacity       : 0.38;
  }
}